import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { useUnmount, useMount } from 'react-use';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Banner from './banner/Banner';
import { locationAtom, popupAtom, storesDialogAtom, userAtom } from '../../../../atoms/Atoms';
import FeedbackFooter from '../../../shared-components/feedback-footer/FeedbackFooter';
import Welcome from '../../../shared-components/welcome/Welcome';
import ServiceSelection from '../../../shared-components/service-selection/ServiceSelection';
import ServicesDrawer from '../../../shared-components/service-selection/shared-components/ServicesDrawer';
import InfoFooter from '../../../shared-components/info-footer/InfoFooter';
import SocialFooter from '../../../shared-components/social-footer/SocialFooter';
import GopanzaFooter from '../../../shared-components/gopanza-footer/GopanzaFooter';
import BrandCarousel from '../../../shared-components/brand-carousel/BrandCarousel';
import SponsoredCarousel from '../../../shared-components/sponsored-carousel/SponsoredCarousel';
import InfocardCarousel from '../../../shared-components/infocard-carousel/InfocardCarousel';
import About from './About';
import Carousel from './carousel/Carousel';

function Home2() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const setDialog = useSetRecoilState(storesDialogAtom);
  const [registerLocation, setRegisterLocation] = useRecoilState(locationAtom);
  const [showPopup, setShowPopup] = useRecoilState(popupAtom);
  const user = useRecoilValue(userAtom);

  useUnmount(() => {
    setDialog(false);
  });

  useMount(() => {
    setShowPopup(true);
  });

  return (
  <div className="flex-1 overflow-y-auto bg-background">
  <motion.div
    initial={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    animate={{
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    exit={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
  >
    <Banner />
    {/* <DynamicBanner /> */}
    <ServicesDrawer />
    <InfocardCarousel />
    {/* <Carousel /> */}
    <div className="flex flex-col px-2 sm:px-12 md:px-24 lg:px-52">
      {/* <ShopperButton /> */}
      {!user && (
        <div className="flex flex-col xl:flex-row items-center justify-center">
          <div 
            role="button"  
            tabIndex={-1}
            onClick={() => {
              history.push('/register');
            }}
            className="bg-base flex flex-row rounded-xl border items-center justify-center text-white p-4 m-4 shadow"
          >
            <div className="font-semibold text-md md:text-lg">{t('request_access')}</div>
          </div>
        </div>
      )}
      <div className="flex flex-col xl:flex-row items-center justify-center">
        <div className="px-2 mt-2"><Welcome /></div>
        <div className="px-2"><ServiceSelection /></div>
      </div>
      <SponsoredCarousel />
      <BrandCarousel />
    </div>
    <About />
    <FeedbackFooter />
    <SocialFooter />
    <InfoFooter />  
    <GopanzaFooter />
  </motion.div>
  </div>
  )
}

export default Home2;
