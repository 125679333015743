import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import is from 'is_js';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faSearch,
  faFilter,
  faRocket,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAmountDown,
  faSortNumericDown,
  faSortNumericUp,
  faTimesCircle,
} from '@fortawesome/pro-duotone-svg-icons';
import { useMount, useUnmount } from 'react-use';
import {
  departmentPageAtom,
  dynamicDepartmentNameAtom,
  departmentsAtom,
  catalogAtom,
  envAtom,
  searchFiltersAtom,
} from '../../../atoms/Atoms';
import Card from '../../shared-components/card/Card';
import Placeholder from './shared-components/Placeholder';
import Breadcrumb from './shared-components/Breadcrumb';
import api from '../../../api/api';
import envConfig from '../../../envConfig';
import { getItemDescription, getItemName } from '../../../functions';


function CustomDepartment() {
  const { i18n, t } = useTranslation();
  const source = axios.CancelToken.source();
  const divRef = useRef(null);
  const { id, searchtype } = useParams();
  const env = useRecoilValue(envAtom);
  const setDynamicDepartmentName = useSetRecoilState(dynamicDepartmentNameAtom);
  const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersAtom);
  const [state, setState] = useRecoilState(departmentPageAtom);
  const [departments, setDepartments] = useRecoilState(departmentsAtom);
  const [catalog, setCatalog] = useRecoilState(catalogAtom);
  const [selectedSearchType, setSelectedSearchType] = useState(null);
  const [sort, setSort] = useState(0);
  const [loading, setLoading] = useState(false);
  const [queryText, setQueryText] = useState('');

  function dynamicDepartmentName() {
    let name = '';
    if (selectedSearchType === 6) {
      name = t('sponsored');
    } else if (selectedSearchType === 7) {
      name = t('buy_again');
    } else if (selectedSearchType === 9) {
      name = t('recently_added');
    } else if (selectedSearchType === 10) {
      name = t('made_locally');
    } else if (selectedSearchType === 5) {
      name = t('offers');
    } else if (selectedSearchType === 4) {
      name = t('wishlist');
    } else if (selectedSearchType === 3) {
      name = t('favorites');
    } else if (selectedSearchType === 12) {
      name = catalog?.customDepartment01Name;
    } else if (selectedSearchType === 13) {
      name = catalog?.customDepartment02Name;
    } else if (selectedSearchType === 14) {
      name = catalog?.customDepartment03Name;
    } else if (selectedSearchType === 30) {
      name = catalog?.customDepartment04Name;
    } else if (selectedSearchType === 31) {
      name = catalog?.customDepartment05Name;
    } else if (selectedSearchType === 32) {
      name = catalog?.customDepartment06Name;
    }
    return name;
  }

  // const dynamicDepartments = [
  //   { shouldShow: catalog?.sponsored, name: t('sponsored'), type: 6 },
  //   { shouldShow: catalog?.myPantry, name: t('buy_again'), type: 7 },
  //   { shouldShow: catalog?.offers, name: t('offers'), type: 5 },
  //   { shouldShow: catalog?.wishlist, name: t('wishlist'), type: 4 },
  //   { shouldShow: catalog?.favorites, name: t('favorites'), type: 3 },
  //   { shouldShow: catalog?.madeLocally, name: t('made_locally'), type: 10 },
  //   { shouldShow: catalog?.recentlyAdded, name: t('recently_added'), type: 9 },
  //   { shouldShow: catalog?.customDepartment01, name: catalog?.customDepartment01Name, type: 12 },
  //   { shouldShow: catalog?.customDepartment02, name: catalog?.customDepartment02Name, type: 13 },
  //   { shouldShow: catalog?.customDepartment03, name: catalog?.customDepartment03Name, type: 14 },
  //   { shouldShow: catalog?.customDepartment04, name: catalog?.customDepartment04Name, type: 30 },
  //   { shouldShow: catalog?.customDepartment05, name: catalog?.customDepartment05Name, type: 31 },
  //   { shouldShow: catalog?.customDepartment06, name: catalog?.customDepartment06Name, type: 32 },
  // ]

  const dynamicDepartments = [
    { showSearch: false,
      shouldShow: catalog?.sponsored,
      name: t('sponsored'),
      type: 6 },
    { showSearch: false,
      shouldShow: catalog?.myPantry,
      name: t('buy_again'),
      type: 7 },
    { showSearch: true,
      shouldShow: catalog?.offers,
      name: t('offers'),
      type: 5 },
    { showSearch: false,
      shouldShow: catalog?.wishlist,
      name: t('wishlist'),
      type: 4 },
    { showSearch: false,
      shouldShow: catalog?.favorites,
      name: t('favorites'),
      type: 3 },
    { showSearch: false,
      shouldShow: catalog?.madeLocally,
      name: t('made_locally'),
      type: 10 },
    { showSearch: false,
      shouldShow: catalog?.recentlyAdded,
      name: t('recently_added'),
      type: 9 },
    { showSearch: false,
      shouldShow: catalog?.customDepartment01,
      name: catalog?.customDepartment01Name, 
      type: 12 },
    { showSearch: false,
      shouldShow: catalog?.customDepartment02,
      name: catalog?.customDepartment02Name, 
      type: 13 },
    { showSearch: false,
      shouldShow: catalog?.customDepartment03,
      name: catalog?.customDepartment03Name, 
      type: 14 },
    { showSearch: false,
      shouldShow: catalog?.customDepartment04,
      name: catalog?.customDepartment04Name, 
      type: 30 },
    { showSearch: false,
      shouldShow: catalog?.customDepartment05,
      name: catalog?.customDepartment05Name, 
      type: 31 },
    { showSearch: false,
      shouldShow: catalog?.customDepartment06,
      name: catalog?.customDepartment06Name, 
      type: 32 },
  ];

  const sortOptions = [
    { text: `${t('recommended')}`, value: 0, icon: faRocket },
    { text: 'A-Z', value: 1, icon: faSortAlphaDown },
    { text: 'Z-A', value: 2, icon: faSortAlphaUp },
    { text: t('lower_price'), value: 3, icon: faSortNumericDown },
    { text: t('greater_price'), value: 4, icon: faSortNumericUp },
    { text: `${t('brand')} (A-Z)`, value: 5, icon: faSortAlphaDown },
    { text: t('recent'), value: 8, icon: faSortAmountDown },
  ];


  const fetchData = () => {    
    if (catalog && selectedSearchType && !loading) {
      const data = {
        SearchType: selectedSearchType,
        SearchString: queryText,
        sort,
        Filters: _.map(state.Filters, (e) => e.id),
      };
      setLoading(true);
      api.post(`businesses/V4/${id}/${envConfig.SearchItemsPageSize}/${state.page}`, data, { cancelToken: source.token })
        .then((response) => {
          setLoading(false);
          setState({
            ...state,
            search: false,
            page: state.page + 1,
            products: _.uniqBy(
              [...state.products, ...response.data.data.items],
              'id'
            ),
            hasMore: response.data.data.items.length >= envConfig.SearchItemsPageSize,
            loading: false,
          });
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.message)
        });
    }
  };

  const fetchFilters = () => {
    if (selectedSearchType) {
      const data = {
        BusinessId: id,
        SearchType: selectedSearchType,
      };
      api.post('businesses/searchFilters', data)
        .then((response) => {
          setSearchFilters(response.data.data.results);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const orderType = (val) => {
    switch (val) {
      case 1:
        return 'A-Z';
      case 2:
        return 'Z-A';
      case 3:
        return 'Menor Precio';
      case 4:
        return 'Mayor Precio';
      case 5:
        return 'Marca (A-Z)';
      case 8:
        return 'Más Recientes';
      default:
        return 'Destacados';
    }
  };

  const getProducts = async () => {
    if (!state.search) {
      divRef.current.scrollIntoView();
      await setState({
        ...state,
        page: 0,
        search: true,
        hasMore: true,
        products: [],
      });
    }
  }

  useEffect(() => {
    if (state.search) {
      fetchData();
    }
  }, [state.search]);

  useEffect(() => {
    fetchData();
    return () => {
      source.cancel();
    };
  }, [sort]);

  useEffect(() => {   
    if (selectedSearchType) {
      setDynamicDepartmentName(dynamicDepartmentName());
      if (loading) {
        source.cancel();
      }
      setState({
        ...state,
        page: 0,
        search: true,
        products: [],
        Filters: []
      });
      setSearchFilters([]);
      fetchFilters();
      // fetchData();
    }
  }, [selectedSearchType]);

  useEffect(() => {
    if (!loading) {
      fetchData();
    }

    return () => {
      source.cancel();
    };
  }, [state.Filters.length]); 

  useEffect(() => {
    if (catalog) {
      setSelectedSearchType(parseInt(searchtype, 10));
    }
  }, [catalog]);

  useMount(() => {
    if (!departments) {
      api.get(`businesses/v2/${id}/web/menu`)
        .then((response) => {
          setCatalog(response.data.data);
          setDepartments(response.data.data.departments);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setSelectedSearchType(parseInt(searchtype, 10));
    }
  });

  useUnmount(() => {
    setDynamicDepartmentName(null);
    setState({
      department: null,
      category: null,
      SearchType: 2,
      page: 0,
      hasMore: true,
      Filters: [],
      products: [],
      loading: true,
      error: null,
    });
    setSearchFilters(null);
    return () => {
      source.cancel();
    };
  });

  return (
    <div className="h-screen flex flex-col overflow-hidden bg-background dark:bg-background">
      <Breadcrumb />     
      <div className="flex container mx-auto space-x-0 md:space-x-4 overflow-hidden p-4">
        <div className="hidden md:flex flex-col w-60 overflow-y-auto rounded-xl mt-0">
          <div className="bg-white rounded-md shadow-sm rounded-xl p-4 mb-4">
            <div className="space-y-2 overflow-y-auto no-scrollbar w-full">
              {catalog && (
                _.map(_.filter(dynamicDepartments, (d) => d.shouldShow), (e) => (
                    <button
                      type="button"
                      key={e.type}
                      disabled={selectedSearchType === e.type}
                      className={`${
                        selectedSearchType === e.type && 'text-accentdark'
                        } w-full truncate line-clamp-1 capitalize disabled:cursor-not-allowed flex text-left text-xs font-medium line-clamp-1 hover:text-accentdark outline-none focus:outline-none transition duration-500 ease-in-out
                      `}
                      onClick={() => {
                          divRef.current.scrollIntoView();
                          setSelectedSearchType(e.type);
                      }}
                    >
                      {e.name}
                    </button>
                ))
              )}
              {!catalog && (
                <>
                    {_.times(20, (e) => (
                      <div
                        key={e}
                        className="h-2 w-full bg-gray-100 rounded-full animate-pulse"
                      />
                    ))}
                </>
              )}
            </div>
          </div>
          {is.not.null(searchFilters) &&
            _.map(searchFilters, (e, index) => (
                <div
                  key={index}
                  hidden={index === 0}
                  className="bg-white rounded-xl shadow-sm p-4 mb-4"
                >
                  <div className="font-extrabold tracking-tight mb-2">
                    {e.groupNameSpa}
                  </div>
                  {_.map(e.filters, (f) => (
                    <div
                      key={f.id}
                      className="flex items-center justify-between text-xs font-medium space-y-2"
                    >
                      <div className="flex items-center space-x-1">
                        <input
                          type="checkbox"
                          name={f.id}
                          checked={_.includes(state.Filters, f)}
                          className="rounded border-gray-300 text-accentdark cursor-pointer"
                          onChange={(event) => {
                            divRef.current.scrollIntoView();
                            if (event.target.checked) {
                              setState({
                                ...state,
                                page: 0,
                                hasMore: true,
                                products: [],
                                Filters: [...state.Filters, f],
                              });
                            } else {
                              setState({
                                ...state,
                                page: 0,
                                hasMore: true,
                                products: [],
                                Filters: _.filter(
                                    state.Filters,
                                    (term) => term.id !== f.id
                                ),
                              });
                            }
                          }}
                        />
                        <div className="capitalize">{f.nameSpa}</div>
                      </div>
                      <div className="text-gray-400">{f.count}</div>
                    </div>
                    ))}
                </div>
            ))}
        </div>
        <div className="flex flex-col flex-grow overflow-y-hidden no-scrollbar space-y-2">
        <div
          className="text-seconadary md:text-xl text-md font-extrabold font-heading tracking-tight truncate mb-0 pb-4"
        >
          {dynamicDepartmentName()}
        </div>       
          <div className="flex flex-col md:flex-row md:items-end justify-between space-y-2">
          {/* <div
            className={`${
                state.products.length
                ? 'text-seconadary'
                : 'text-gray-800 animate-pulse'
              } md:text-3xl text-lg font-extrabold font-heading tracking-tight truncate`}
          >
              {loading ?
                  'Cargando Productos...' : 
                  dynamicDepartmentName()}
          </div> */}
        <div className="flex flex-row w-full">
          <div
            className="flex flex-1 w-full rounded-md bg-green-200"
            hidden={!_.filter(dynamicDepartments,
              (d) => d.type === selectedSearchType)[0]?.showSearch}
          >
            <input
              className="w-50 border-0 float-right focus:ring-0 h-10 block w-full rounded-l-md text-gray-400 placeholder-gray-400 font-medium text-xs tracking-tight"
              type="search"
              name="search"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              value={queryText}
              onKeyDown={(event) => {
                if (event.key === 'Enter') getProducts();
              }}
              onChange={(event) => setQueryText(event.target.value)}
              placeholder={`${t('search_in')} ${dynamicDepartmentName()}...`}
            />
            <button
              type="button"
              onClick={() => {
                getProducts();
              }}
              className="md:full border-l inline-flex items-center space-x-2 px-4 font-medium tracking-tight rounded-r-md bg-accent focus:outline-none transition duration-500 ease-in-out"
            >
              <FontAwesomeIcon icon={faSearch} className="text-white hover:text-searchText" />
            </button>
          </div> 
          {is.not.empty(state.Filters) && (
            <div className="flex items-center space-x-2 mb-4">
              <div className="text-xs flex items-center space-x-1">
                <FontAwesomeIcon icon={faFilter} />
                <div>Filtros:</div>
              </div>
              {_.map(state.Filters, (f) => (
                <div
                  key={f.id}
                  className="flex items-center space-x-2 text-xs px-2 py-1 rounded-full font-semibold text-white bg-accentdark"
                >
                  <div className="capitalize">{_.toLower(f.name)}</div>
                  <button
                    type="button"
                    className="hover:opacity-75 outline-none focus:outline-none"
                    onClick={() => {
                      divRef.current.scrollIntoView();
                      setState({
                        ...state,
                        page: 0,
                        hasMore: true,
                        products: [],
                        Filters: _.filter(state.Filters, (term) => term.id !== f.id),
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>          
              {/* <div className="grid grid-cols-2 md:grid-cols-1 gap-2">
              <div className="relative md:hidden"> */}
              <div className="grid grid-cols-1 gap-2">
                <div hidden className="relative md:hidden">
                  <Menu>
                    {({ open }) => (
                      <>
                        <Menu.Button className="w-full flex items-center justify-between rounded-md shadow-sm px-4 py-3 space-x-4 bg-white text-xs font-medium hover:bg-gray-50 outline-none focus:outline-none">
                          <span>Categorias</span>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={
                              open
                              ? 'transform rotate-180 transition duration-500 easy-in-out'
                              : 'transition duration-500 easy-in-out'
                            }
                          />
                        </Menu.Button>
                        <Transition
                          show={open}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            style={{ zIndex: '100' }}
                            static
                            className="absolute right-0 w-full mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-xl shadow outline-none max-h-60 overflow-y-auto no-scrollbar"
                          >
                            {catalog &&
                              _.map(_.filter(dynamicDepartments, (d) => d.shouldShow), (e) => (
                                <Menu.Item key={e.type}>
                                  <button
                                    type="button"
                                    disabled={
                                      selectedSearchType === e.type
                                    }
                                    onClick={() => {
                                      divRef.current.scrollIntoView();
                                      setSelectedSearchType(e.type);
                                    }}
                                    className={`${
                                      selectedSearchType === e.type &&
                                      'text-accentdark'
                                      } disabled:cursor-not-allowed text-xs font-light
                                      tracking-tight flex items-center space-x-2 w-full
                                      p-4 leading-none text-left hover:text-accent
                                      transition duration-500 easy-in-out truncate
                                      line-clamp-1`}
                                  >
                                    {e.name}
                                  </button>
                                </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
                <div className="relative w-full z-10">
                <Menu>
                  {({ open }) => (
                    <>
                      <Menu.Button className="w-full md:w-40  flex items-center justify-between rounded-md shadow-sm px-4 py-3 space-x-4 bg-white text-xs font-medium hover:bg-gray-50 outline-none focus:outline-none">
                        <span>{orderType(state.sort)}</span>
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={
                            open
                              ? 'transform rotate-180 transition duration-500 easy-in-out'
                              : 'transition duration-500 easy-in-out'
                          }
                        />
                      </Menu.Button>
                      <Transition
                        show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          style={{ zIndex: '100' }}
                          className="absolute right-0 w-full mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-xl shadow outline-none max-h-60 overflow-y-auto no-scrollbar"
                        >
                          {_.map(sortOptions, (e) => (
                            <Menu.Item key={e.value}>
                              <button
                                type="button"
                                disabled={state.sort === e.value}
                                onClick={() => {
                                  divRef.current.scrollIntoView();
                                  setState({
                                    ...state,
                                    sort: e.value,
                                    page: 0,
                                    hasMore: true,
                                    products: [],
                                  });
                                }}
                                className={`${
                                  state.sort === e.value && 'text-accent'
                                } disabled:cursor-not-allowed text-xs font-light tracking-tight text-left flex items-center space-x-2 w-full p-4 leading-none hover:text-accentdark transition duration-500 easy-in-out truncate line-clamp-1`}
                              >
                                <FontAwesomeIcon icon={e.icon} />
                                <span>{e.text}</span>
                              </button>
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
                </div>
              </div>
          </div>
          {is.not.empty(state.Filters) && (
            <div className="flex items-center space-x-2 mb-4">
              <div className="text-xs flex items-center space-x-1">
                <FontAwesomeIcon icon={faFilter} />
                <div>Filtros:</div>
              </div>
              {_.map(state.Filters, (f) => (
                <div
                  key={f.id}
                  className="flex items-center space-x-2 text-xs px-2 py-1 rounded-full font-semibold text-white bg-accentdark"
                >
                  <div className="capitalize">{_.toLower(f.name)}</div>
                  <button
                    type="button"
                    className="hover:opacity-75 outline-none focus:outline-none"
                    onClick={() => {
                      divRef.current.scrollIntoView();
                      setState({
                        ...state,
                        page: 0,
                        hasMore: true,
                        products: [],
                        Filters: _.filter(state.Filters, (term) => term.id !== f.id),
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                  </button>
                </div>
              ))}
            </div>
          )}
          <div className="overflow-y-auto rounded-md overflow-y-auto no-scrollbar">
              <InfiniteScroll
                // placeholderItem={<Placeholder />}
                // enablePlaceholder
                // numberOfPlaceholderItems={is.not.empty(state.products) ? 4 : 16}
                pageStart={state.page}
                loadMore={fetchData}
                hasMore={state.hasMore}
                loader={
                  <Placeholder
                    key={0}
                    quantity={is.not.empty(state.products) ? 4 : 16}
                    initial={is.empty(state.products)}
                  />
                }
                useWindow={false}
              >
                <div
                  ref={divRef}
                  className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
                >
                  {_.map(state.products, (e) => (
                    <Card
                      key={e.id}
                      name={getItemName(i18n.language, e)}
                      description={getItemDescription(i18n.language, e)}
                      image={e.imageUrl || null}
                      id={e.id}
                      brand={e.brand}
                      price={e.activePrice}
                      product={e}
                    />
                  ))}
                </div>
              </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomDepartment;
