import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import is from 'is_js';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  departmentAtom,
  departmentPageAtom,
  catalogAtom,
  departmentsAtom,
} from '../../../../atoms/Atoms';
import Placeholder from './Placeholder';
import Breadcrumb from './Breadcrumb';
import Banner from '../../../shared-components/banners/Banner';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';

function Departments1() {
  const { i18n, t } = useTranslation();
  const departments = useRecoilValue(departmentsAtom);
  const setCatalog = useSetRecoilState(catalogAtom);
  const setDepartment = useSetRecoilState(departmentAtom);
  const setDepartments = useSetRecoilState(departmentsAtom);
  const [state, setState] = useRecoilState(departmentPageAtom);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    api.get(`businesses/v2/${id}/web/menu`)
      .then((response) => {
        setCatalog(response.data.data);
        setDepartments(response.data.data.departments);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);


  return (
    <div className="flex-1 overflow-y-auto">
      <motion.div
        initial={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        exit={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
      >
        <Banner title={t('departments')} image="bg-store" />
        <Breadcrumb />
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-4 p-4">
          {is.not.null(departments) &&
            _.map(departments, (d) => (
              <div
                key={d.id}
                className="flex flex-col text-left rounded-xl bg-white border"
              >
                <div className="flex items-center space-x-2 text-base font-extrabold tracking-tight border-b border-gray-100 leading-none mb-2 p-4">
                  {/* <FontAwesomeIcon icon={getIcon(d.name)} /> */}
                  <div className="line-clamp-1">{i18n.language === 'es' ? d.nameSpa : d.nameEng}</div>
                </div>
                <div className="flex flex-col flex-1 mb-2 p-4">
                  {_.map(_.slice(d.categories, 0, 5), (c) => (
                    <button
                      type="button"
                      key={c.id}
                      className="text-left text-xs hover:text-accent transition duration-500 easy-in-out outline-none focus:outline-none"
                      onClick={() => {
                        setDepartment(d);
                        history.push(`/stores/${id}/departments/${d.id}`);
                        setState({
                          ...state,
                          category: c,
                        });
                      }}
                    >
                      {i18n.language === 'es' ? c.nameSpa : c.nameEng}
                    </button>
                  ))}
                </div>
                <button
                  type="button"
                  className="text-right bg-gray-50 rounded-b-xl p-4 border-t border-gray-100 text-left space-x-1 text-xs font-medium text-accent hover:underline outline-none focus:outline-none transition duration-500 ease-in-out"
                  onClick={() => {
                    setDepartment(d);
                    history.push(`/stores/${id}/departments/${d.id}`);
                  }}
                >
                  <span>{t('show_more')}</span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            ))}
          {is.null(departments) && _.times(9, (e) => <Placeholder key={e} />)}
        </div>
      </motion.div>
    </div>
  ) 
}

export default Departments1;
