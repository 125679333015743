import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import _ from 'lodash';
import ItemsCarousel from 'react-items-carousel';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowToRight,
  faArrowToLeft
} from '@fortawesome/pro-duotone-svg-icons';
import {
  showShopperAtom,
  notificationsAtom,
  selectedShopperPageAtom,
  shopperPagesAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import Loader from '../../loader/Loader';
import useBreakpoint from '../../../../hooks/useBreakpoint';

function Shopper2() {
  const breakpoint = useBreakpoint();
  const { t, i18n } = useTranslation();
  const setSelectedShopperPage = useSetRecoilState(selectedShopperPageAtom);
  const [showShopper, setShowShopper] = useRecoilState(showShopperAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [shopperPages, setShopperPages] = useRecoilState(shopperPagesAtom);
  const [loading, setLoading] = useState(true);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  function itemsToShow(bp) {
    // if (bp === 7) {
    //   return 6; 
    // }
    // if (bp === 4) {
    //   return 3; 
    // }
    // if (bp === 2) {
    //   return 1; 
    // }
    // if (bp === 2) {
    //   return 1; 
    // }
    return bp - 1;
  } 

  function getShopperPages() {
    setLoading(true);
    api.get('test/shopperImages/shopper')
    .then((response) => {
      setLoading(false);
      const tempPages = [];
      for (let i = 0; i < response.data?.data?.length; i++) {
        tempPages.push({
          zoomed: false,
          index: i,
          image: response.data.data[i]
        })
      }
      setShopperPages(tempPages);
      if (response.data?.data?.length === 0) {
        setShowShopper(false);
        setSelectedShopperPage(null);
      }
    })
    .catch((error) => {
      setNotifications([
        ...notifications,
        {
          title: 'Error ',
          description: `${t('could_not_load_shopper')} ${error}`,
          error: true,
        },
      ]);
      setShowShopper(false);
      setSelectedShopperPage(null);
    });
  }

  useEffect(() => {
    if (shopperPages) {
        getShopperPages();
    } else {
        setShopperPages([]);
    }
  }, [showShopper]);

   return (
    <div className="flex-1 overflow-y-auto">
      <motion.div
        initial={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        exit={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
      >
        {loading && (
          <div className="mt-40 flex flex-col flex-1 items-center justify-center">
            <Loader color="bg-gray-500" />
          </div>
        )}
        {shopperPages?.length > 1 && (
          <ItemsCarousel
            gutter={1}
            activePosition="right"
            chevronWidth={50}
            disableSwipe={false}
            alwaysShowChevrons
            numberOfCards={itemsToShow(breakpoint)}
            slidesToScroll={1}
            showSlither
            firstAndLastGutter
            activeItemIndex={activeItemIndex}
            requestToChangeActive={setActiveItemIndex}
            rightChevron={
              <button
                type="button"
                className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-secondary"
              >
                <FontAwesomeIcon icon={faArrowToRight} />
              </button>
            }
            leftChevron={
              <button
                type="button"
                className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-secondary"
              >
                <FontAwesomeIcon icon={faArrowToLeft} />
              </button>
            }
          >
            {_.map(shopperPages, (e, index) => (
              <button
                type="button"
                onClick={() => {
                  setSelectedShopperPage(e);
                }}
              >
                <img
                  key={index}
                  alt={`${t('page')}: ${index}`}
                  src={e.image}
                  className="h-auto w-auto md:h-full md:w-auto mx-4 transform ease-out duration-500 hover:scale-110"
                />
              </button>
            ))}
          </ItemsCarousel>
        )}
      </motion.div>
    </div>
  )
}

export default Shopper2;
