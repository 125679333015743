import React, { useEffect } from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { storeAtom } from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';
import Store1 from './shared-components/Store1';
import Store2 from './shared-components/Store2';
import Store3 from './shared-components/Store3';
import useAnalyticsEventTracker from '../../../useAnalyticsEventTracker';

function Store() {
  const store = useRecoilValue(storeAtom);
  const eventTracker = useAnalyticsEventTracker('User Actions');
  useEffect(() => {
    eventTracker('Entered Store', store?.name);
  }, []);

  if (envConfig.StoreVersion === '1') {
    return (
      <Store1 />
    )
  } 
  if (envConfig.StoreVersion === '2') {
    return (
      <Store2 />
    )
  } 
  if (envConfig.StoreVersion === '3') {
    return (
      <Store3 />
    )
  } 
  return (
    <Store1 />
  )
} 

export default Store;
