import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PayPalButton } from 'react-paypal-button-v2';
import { useRecoilState } from 'recoil';
import { notificationsAtom } from '../../../atoms/Atoms';
import api from '../../../api/api';
import Loader from '../../shared-components/loader/Loader';
import envConfig from '../../../envConfig';

function PayPalPay() {
  const history = useHistory();
  const params = useParams();
  const [order, setOrder] = useState(null);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [loading, setLoading] = useState(true);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (params) {
      setLoading(true);
      api
        .get(`orders/order/${params.confirmationCode}?groupedItems=${envConfig.GroupedItems}`)
        .then((response) => {
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: t('error_searching_order'),
                description: response.data.error,
                error: true,
              },
            ]);
            history.push('/');
          } else if (
            response.data.data.status === 36 || // Waiting For Payment
            response.data.data.status === 30 || // Pending Payment
            response.data.data.status === 27 || // Pending PayPal Payment
            response.data.data.status === 22 || // Pending AHT Movil Payment
            response.data.data.status === 26 || // Pending EBT Payment
            response.data.data.status === 6 || // Customer Picked Up
            response.data.data.status === 7 // Delivereds
          ) {
            setOrder(response.data.data);
            setPaymentCompleted(
              response.data.data.status === 6 || response.data.data.status === 7
            );
            setLoading(false);
          } else if (
            response.data.data.status === 8 ||
            response.data.data.status === 9
          ) {
            setNotifications([
              ...notifications,
              {
                title: 'Error',
                description: t('order_cancelled'),
                error: true,
              },
            ]);
            history.push('/');
          } else {
            setNotifications([
              ...notifications,
              {
                title: 'Error',
                description:
                  t('order_not_accept_payment'),
                error: true,
              },
            ]);
            history.push('/');
          }
        })
        .catch((error) => {
          setNotifications([
            ...notifications,
            {
              title: 'Error ',
              description: t('complete_payment_error')`${error}`,
              error: true,
            },
          ]);
          history.push('/');
        });
    }
  }, [params]);
  return (
    <div>
      {loading && (
        <div className="flex flex-col flex-1 items-center justify-center">
          <h1>{t('loading')}</h1>
          <Loader color="bg-gray-500" />
        </div>
      )}
      {!loading && order && !paymentCompleted && (
        <div className="flex flex-col flex-1 items-center justify-center">
          <PayPalButton
            options={{
              clientId: envConfig.PAYPAL_CLIENTID,
            }}
            createOrder={(data, actions) => {
              actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: 'USD',
                      value: order.orderTotal,
                    },
                    reference_id: order.confirmationCode,
                    custom_id: order.confirmationCode,
                    description: order.confirmationCode,
                  },
                ],
                application_context: {
                  shipping_preference: 'NO_SHIPPING',
                },
              });
            }}
            onApprove={(data, actions) => {
              actions.order.capture().then((details) => {
                const paypalId = details.id;
                const captureId =
                  details.purchase_units[0].payments.captures[0].id;
                setLoading(true);
                api
                  .post('orders/paypalupdate', {
                    OrderId: order.id,
                    PaypalId: paypalId,
                    CaptureId: captureId,
                    Logs: {
                      SendLog: JSON.stringify({
                        purchase_units: [
                          {
                            amount: {
                              currency_code: 'USD',
                              value: order.orderTotal,
                            },
                            reference_id: order.confirmationCode,
                            custom_id: order.confirmationCode,
                            description: order.confirmationCode,
                          },
                        ],
                        application_context: {
                          shipping_preference: 'NO_SHIPPING',
                        },
                      }),
                      ReceiveLog: JSON.stringify(details),
                    },
                  })
                  .then((response) => {
                    if (response.data.error) {
                      setNotifications([
                        ...notifications,
                        {
                          title: t('error_saving_payment'),
                          description: response.data.error,
                          error: true,
                        },
                      ]);
                      history.push('/');
                    } else {
                      setLoading(false);
                      setPaymentCompleted(true);
                    }
                  })
                  .catch((error) => {
                    setNotifications([
                      ...notifications,
                      {
                        title: 'Error ',
                        description: t('complete_payment_error')`${error}`,
                        error: true,
                      },
                    ]);
                    history.push('/');
                  });
              });
            }}
          />
        </div>
      )}
      {!loading && order && paymentCompleted && (
        <div className="flex flex-col flex-1 items-center justify-center">
          <h1>{t('payment_completed')}</h1>
        </div>
      )}
    </div>
  );
}

export default PayPalPay;
