import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function FeedbackFooter() {
    const { t, i18n } = useTranslation();
    return (<div className="h-24 flex flex-col items-center justify-center px-2">
                <div
                  tabIndex={0}
                  role="button"
                  className="border rounded-md border-primary bg-primary"
                  onKeyDown={(e) => {
                    window.location.href = 'mailto:yourmail@gmail.com';
                    e.preventDefault();
                  }}
                  onClick={(e) => {
                    window.location.href = 'mailto:info@gopanza.com?body=I want to provide Feedback';
                    e.preventDefault();
                  }}
                >
                <div className="px-4 py-2 text-white">
                 {t('prompt_feedback')}
                </div>                 
                </div>
            </div>)
}
export default FeedbackFooter;
