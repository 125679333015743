import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function About() {
  const { t, i18n } = useTranslation();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 my-4">
      <div className="flex flex-shrink-0 text-white bg-about bg-center bg-cover mx-14 h-28 md:h-72">
        <div className="flex flex-col flex-1 justify-end p-4 mx-14" />
      </div>
      <div>
        <p className="font-bold text-3xl text-gray-600 mb-6">{t('site_name')}</p>
        {t('about_text')}
      </div>
    </div>
  );
}

export default About;
