import React from 'react';
import { toCurrency } from '../../../../functions';

function Price({ price, hasRandomWeight }) {
  if (hasRandomWeight) {
    return (
      <div className="font-extrabold font-light tracking-tight py-2">
        <div className="">{toCurrency(parseFloat(price))} lb.</div>
      </div>
    );
  }

  return (
    <div className="font-extrabold font-light tracking-tight py-2">
      {toCurrency(parseFloat(price))}
    </div>
  );
}

export default Price;
