import React from 'react';

function Form({ children, className }) {
  return (
    <form
      noValidate
      autoComplete="off"
      className={`grid grid-cols-1 md:grid-cols-2 gap-4 text-xs py-4 w-full ${
        className && className
      }`}
    >
      {children}
    </form>
  );
}

export default Form;
