import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift } from '@fortawesome/pro-duotone-svg-icons';
import { userAtom, loyaltyPointsAtom, notificationsAtom } from '../../../../../atoms/Atoms';
import { H3 } from '../../../../shared-components/typography/Title';
import Subtitle from '../../../../shared-components/typography/Subtitle';
import Description from '../../../../shared-components/typography/Description';
import Panel from '../../../../shared-components/panel/Panel';
import api from '../../../../../api/api';
import { Spinner } from '../../../../shared-components/Spinner';
import { formatNumber } from '../../../../../functions';

function Loyalty() {
  const { t, i18n } = useTranslation();
  const user = useRecoilValue(userAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const setLoyaltyPoints = useSetRecoilState(loyaltyPointsAtom);
  const [loyalty, setLoyalty] = useState({});
  const [loading, setLoading] = useState(false);

  function getLoyalty() {
    setLoading(true);
    api.get('loyalty/history')
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('profile_load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        } else {
          setLoyaltyPoints(response.data.data.points);
          setLoyalty(response.data.data);
          // console.log('list to graph', response.data.data.balanceHistory);
          // console.log('list to show', response.data.data.history);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('profile_load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  function share() {
    if (navigator.share) {
      navigator
        .share({
          url: `/register/${user.friendCode}`,
          title: t('referral_link_title'),
          text: t('referral_link_text')
        })
        .then(() => {
          // console.log('Sharing successfull');
        })
        .catch(() => {
          // console.error('Sharing failed');
        });
    } else {
      console.log('Sorry! Your browser does not support Web Share API');
    }
  }

  useEffect(() => {
    getLoyalty();
  }, []);

  return (
    <div className="flex flex-col flex-1 px-4 mb-4 overflow-auto space-y-4">
      <Panel>
        <H3 text={t('invite_new_user')} />
        <Subtitle text={t('referral_description')} />
        <div className="mt-4 w-full text-center">
          <div className="text-xs md:text-sm tracking-tight">
            {t('use_code')}
            <strong className="mx-1">{user?.friendCode}</strong>
            {t('at_signup')}
          </div>
          <Subtitle text={t('or')} />
          <button
            type="button"
            onClick={() => share()}
            className="text-xs md:text-sm tracking-tight text-accent"
          >
            {t('send_invite_link')}
          </button>
        </div>
      </Panel>
      <Panel>
        <H3 text={loading ? t('points') : `${t('points')}: ${formatNumber(loyalty?.points)}`} />
        <Spinner spinning={loading} className="mx-auto" />

      </Panel>
    </div>
  )
}

export default Loyalty;
