import React from 'react';

function InformationLabel({
  value,
  label,
  className,
  uppercase,
}) {
  return (
    <div className={`${className} w-full text-xs tracking-tight space-y-1`}>
      {label && (
        <div className="font-semibold">
          {label}
        </div>
      )}
      <div
        className={`${uppercase ? 'uppercase' : ''} disabled:bg-gray-100 disabled:text-gray-400 placeholder-gray-400 text-gray-900 text-sm rounded-md p-3 w-full appearance-none border border-gray-200 outline-none transition ease-in-out duration-500`}
      >
        {value}
      </div>
    </div>
  );
}

export default InformationLabel;
