import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { orderDetailsModalAtom, orderDetailsAtom } from '../../../../../../../../atoms/Atoms';
import { thousand, toCurrency } from '../../../../../../../../functions';

function OrderProducts() {
  const { i18n, t } = useTranslation();
  const orderDetailsModal = useRecoilValue(orderDetailsModalAtom);
  const orderDetails = useRecoilValue(orderDetailsAtom);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (orderDetailsModal) {
      setOrder(orderDetailsModal);
    } else if (orderDetails) {
      setOrder(orderDetails);
    }
  }, [orderDetailsModal, orderDetails]);

  return (
    <div className="space-y-4 p-4 bg-gray-50 rounded-b-xl">
      <div className="text-sm font-bold tracking-tight leading-none">
        {t('products_ordered')}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
        {order &&
          _.map(order.items, (item) => (
            <div
              key={item.id}
              className={`${
                item.isVoided
                  ? 'bg-opacity-60 border border-gray-100'
                  : 'border border-gray-100'
              }
                'group relative flex flex-col items-center bg-white rounded-xl text-xs p-4 h-32'
              `}
            >
              <div>
                <img
                  alt={item.name}
                  src={item.itemImage}
                  className="h-16 mb-2"
                />
              </div>
              <div className="w-full truncate capitalize font-semibold text-center">
                {_.toLower(item.name)}
              </div>
              <div className="w-full truncate font-semibold text-center">
                {item.brand}
              </div>
              <div>
                {toCurrency(item.price)}
                {(item.hasRandomWeight ||
                  item.amount !== 1) && <span> x {thousand(item.amount)}</span>}
              </div>
              {item.isVoided && (
                <div className="absolute right-3 top-3 text-xs bg-gray-300 ring-2 ring-offset-2 ring-gray-300 px-3 py-1 rounded-full text-white font-semibold tracking-tight">
                  {t('not_available')}
                </div>
              )}
              {item.specialInstruction && (
                <div className="text-xs px-3 py-1 rounded-full text-gray-500 font-semibold tracking-tight">
                  {item.specialInstruction}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

export default OrderProducts;
