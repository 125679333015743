import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { matchSorter } from 'match-sorter';
import InfiniteScroll from 'react-infinite-scroller';
import {
  searchBrandAtom,
  searchPageAtom,
  searchStringAtom,
  storeAtom,
  notificationsAtom,
  departmentDrawerAtom,
  showShopperAtom
} from '../../../atoms/Atoms';
import Card from '../../shared-components/card/Card';
import Breadcrumb from './shared-components/Breadcrumb';
import Loader from '../../shared-components/loader/Loader';
import envConfig from '../../../envConfig';
import api from '../../../api/api';
import { getItemDescription, getItemName, thousand } from '../../../functions';

function Search() {
  const { t, i18n } = useTranslation();
  const searchString = useRecoilValue(searchStringAtom);
  const store = useRecoilValue(storeAtom);
  const [search, setSearch] = useRecoilState(searchPageAtom);
  const [filter, setFilter] = useRecoilState(searchBrandAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const setDeptsOpen = useSetRecoilState(departmentDrawerAtom);
  const setShowShopper = useSetRecoilState(showShopperAtom)

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };

  const getProducts = () => {
    if (!search.loadingMore) {
      setSearch({
        ...search,
        loadingMore: true,
      });
  
      const p = parseInt(search.page, 10) + 1;
      api
        .post(`businesses/v4/${store.id}/${envConfig.SearchItemsPageSize}/${p}`, {
          SearchType: 0,
          searchString,
        })
        .then((response) => {
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: `${t('oops')}`,
                description:
                `${t('search_error')}`,
                error: true,
              },
            ]);
            setSearch({
              ...search,
              loadMore: false,
              loadingMore: false,
            });
          } else {
            const newItems = search.products.concat(response.data.data.items);
            const brands = _.map(
              _.uniqBy(newItems, 'brand'),
              (i) => i.brand
            );
            setSearch({
              ...search,
              page: p,
              loadingMore: false,
              products: newItems,
              brands,
              loadMore: response.data.data.items.length === envConfig.SearchItemsPageSize
            });
          }
        })
        .catch((error) => {
          setSearch({
            ...search,
            loadMore: false,
            loadingMore: false
          });
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
              `${t('search_error')}`,
              error: true,
            },
          ]);
        });
    }
  };

  return (
    <motion.div
      initial={{
        y: 100,
        opacity: 0,
        transition: {
          duration: 0.5,
          ease: [0.43, 0.13, 0.23, 0.96],
        },
      }}
      animate={{
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: [0.43, 0.13, 0.23, 0.96],
        },
      }}
      exit={{
        y: 100,
        opacity: 0,
        transition: {
          duration: 0.5,
          ease: [0.43, 0.13, 0.23, 0.96],
        },
      }}
      className="flex flex-col flex-1 overflow-hidden lg:container lg:mx-auto px-4 lg:px-0"
    >
      <Breadcrumb />
      <div className="flex flex-col flex-1 bg-white rounded-t-xl overflow-y-auto no-scrollbar">
        <div
          className={`${
            search.loading ? 'text-gray-200 animate-pulse' : 'text-gray-900 '
          } flex flex-shrink-0 text-xl font-extrabold tracking-tight truncate border-b p-4`}
        >
          {`${t('search_results')} (${thousand(search.products.length)})`}
        </div>
        {search.loading && (
          <div className="flex flex-col flex-1 items-center justify-center">
            <Loader color="bg-accent" />
          </div>
        )}
        {!search.loading && search.products.length === 0 && (
          <div className="flex flex-col flex-1 items-center justify-center">           
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{
                duration: 3,
                type: 'spring',
                stiffness: 260,
                damping: 20,
              }}
              className="text-center tracking-tight"
            >
              <div className="text-xl font-extrabold">
                No encontramos productos
              </div>
              <div className="text-sm">
                Favor de intentar hacer otra busqueda
              </div>
            </motion.div>
          </div>
        )}
        {!search.loading && search.products.length > 0 && (
          <div className="overflow-y-auto flex-col flex-1 p-4">
            <InfiniteScroll
              pageStart={0}
              scrollThreshold={0.3}
              loadMore={getProducts}
              hasMore={search.loadMore}
              loader={
                <div key={0} className="p-4 flex flex-col flex-1 items-center justify-center">
                  <Loader color="bg-accent" />
                </div>
              }
              useWindow={false}
            >
              <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
              >
                {_.map(
                  filter !== ''
                    ? matchSorter(search.products, filter, {
                        keys: [
                          {
                            minRanking: matchSorter.rankings.EQUAL,
                            key: 'brand',
                          },
                        ],
                      })
                    : search.products,
                  (e) => (
                    <motion.div
                      key={e.id}
                      variants={item}
                      whileHover={{
                        scale: 1.05,
                        transition: {
                          duration: 0.4,
                          type: 'tween',
                          ease: 'easeIn',
                        },
                      }}
                    >
                      <Card
                        name={getItemName(i18n.language, e)}
                        description={getItemDescription(i18n.language, e)}
                        image={e.imageUrl || null}
                        id={e.id}
                        brand={e.brand}
                        price={e.activePrice}
                        product={e}
                      />
                    </motion.div>
                  )
                )}
              </motion.div>
            </InfiniteScroll>
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default Search;
