import React from 'react';
import Cleave from 'cleave.js/react';

function CleaveInput({
  id,
  name,
  value,
  onChange,
  autoComplete,
  required,
  placeholder,
  autoCapitalize,
  type,
  label,
  error,
  className,
  disabled,
  options,
}) {
  return (
    <div className={`${className} w-full text-xs tracking-tight space-y-1`}>
      {label && (
        <div className="font-semibold">
          {label} {required && <span className="text-red-400">*</span>}
        </div>
      )}
      <Cleave
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        value={value}
        disabled={disabled}
        onChange={onChange}
        options={options}
        className="placeholder-gray-300 text-gray-900 text-sm rounded-md p-3 w-full appearance-none focus:ring-1 focus:ring-accent border border-gray-200 focus:border-accent outline-none focus:outline-none transition ease-in-out duration-500"
      />
      {error && <div className="text-xs font-light text-red-400">{error}</div>}
    </div>
  );
}

export default CleaveInput;
