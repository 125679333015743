import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons';
import { useRecoilValue } from 'recoil';
import { storeAtom } from '../../../../atoms/Atoms';

function Breadcrumb() {
  const { t, i18n } = useTranslation();
  const store = useRecoilValue(storeAtom);

  return (
    <div className="mx-4 flex flex-shrink-0 items-center w-full text-xs tracking-tight p-4 space-x-1 truncate">
      {/* <NavLink
        to="/"
        className="hover:text-accent transition duration-500 ease-in-out"
      >
        {t('home')}
      </NavLink> */}
      {/* <FontAwesomeIcon icon={faChevronRight} /> */}
      <div className="text-gray-800">{store && store.name}</div>
    </div>
  );
}

export default Breadcrumb;
