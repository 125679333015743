import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useClickAway } from 'react-use';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimesCircle,
  faArrowToRight,
  faArrowToLeft
} from '@fortawesome/pro-duotone-svg-icons';
import {
  shopperPagesAtom,
  selectedShopperPageAtom,
  notificationsAtom
} from '../../../atoms/Atoms';
import { backdropVariants } from '../../../utils';
import Shopper1 from './shared-components/Shopper1';
import Shopper2 from './shared-components/Shopper2';
import ShopperImage from './shared-components/ShopperImage';
import envConfig from '../../../envConfig';
import api from '../../../api/api';

function MobileShopper() {
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [selectedShopperPage, setSelectedShopperPage] = useRecoilState(selectedShopperPageAtom);
  const [shopperPages, setShopperPages] = useRecoilState(shopperPagesAtom);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  function getShopperPages() {
    setLoading(true);
    api.get('test/shopperImages/shopper')
    .then((response) => {
      setLoading(false);
      const tempPages = [];
      for (let i = 0; i < response.data?.data?.length; i++) {
        tempPages.push({
          zoomed: false,
          index: i,
          image: response.data.data[i]
        })
      }
      setShopperPages(tempPages);
      setSelectedShopperPage(tempPages[0]);
    })
    .catch((error) => {
      setNotifications([
        ...notifications,
        {
          title: 'Error ',
          description: `${t('could_not_load_shopper')} ${error}`,
          error: true,
        },
      ]);
      setSelectedShopperPage(null);
    });
  }

  useEffect(() => {
    getShopperPages();
  }, []);

  return (
    <AnimatePresence>
      <>
        {!loading && (
          <motion.div
            variants={{
                visible: {
                  opacity: 1,
                  transition: {
                    duration: 0.0,
                  },
                },
                hidden: {
                  opacity: 1,
                  transition: {
                    duration: 0.0,
                  },
                },
              }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{ zIndex: 1001 }}
            className="fixed bg-white inset-0 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
          >
            <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="my-auto mx-auto w-full md:px-32 xl:w-2/3 px-4">
                <div className="flex items-center md:mt-10 justify-end">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedShopperPage(null);
                    }}
                    className="hover:opacity-75 absolute -mt-5 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-white" style={{ fontSize: '42px' }} />
                  </button>
                </div>
                <div className="flex justify-center items-center">
                  <button
                    type="button"
                    onClick={() => {
                      let tempIndex = selectedShopperPage?.index - 1;
                      if (tempIndex < 0) {
                        tempIndex *= -1;
                      }
                      setSelectedShopperPage(shopperPages[tempIndex % shopperPages?.length]);
                    }}
                    className="hidden md:flex items-center justify-center mr-2 p-2 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-secondary"
                  >
                    <FontAwesomeIcon icon={faArrowToLeft} style={{ fontSize: '40px' }} />
                  </button>
                  <img
                    alt={`${t('page')}: ${selectedShopperPage?.index}`}
                    src={selectedShopperPage?.image}
                    className="h-full w-auto flex-col pt-4 my-auto mx-auto ease-out"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      let tempIndex = selectedShopperPage?.index + 1;
                      if (tempIndex < 0) {
                        tempIndex *= -1;
                      }
                      setSelectedShopperPage(shopperPages[tempIndex % shopperPages?.length]);
                    }}
                    className="hidden md:flex items-center justify-center ml-2 p-2 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-secondary"
                  >
                    <FontAwesomeIcon icon={faArrowToRight} style={{ fontSize: '40px' }} />
                  </button>
                </div>
                <div className="flex md:hidden mt-2 justify-center">
                  <button
                    type="button"
                    onClick={() => {
                      let tempIndex = selectedShopperPage?.index - 1;
                      if (tempIndex < 0) {
                        tempIndex *= -1;
                      }
                      setSelectedShopperPage(shopperPages[tempIndex % shopperPages?.length]);
                    }}
                    className="flex md:hidden items-center justify-center mr-2 p-2 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-secondary"
                  >
                    <FontAwesomeIcon icon={faArrowToLeft} style={{ fontSize: '40px' }} />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      let tempIndex = selectedShopperPage?.index + 1;
                      if (tempIndex < 0) {
                        tempIndex *= -1;
                      }
                      setSelectedShopperPage(shopperPages[tempIndex % shopperPages?.length]);
                    }}
                    className="flex md:hidden items-center justify-center mr-2 p-2 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-secondary"
                  >
                    <FontAwesomeIcon icon={faArrowToRight} style={{ fontSize: '40px' }} />
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </>
    </AnimatePresence>
  )
} 

export default MobileShopper;
