import React from 'react';
import { useTranslation } from 'react-i18next';
import envConfig from '../../../envConfig';

function Privacy() {  
  const { t, i18n } = useTranslation();

  return (
    <div className="overflow-y-auto mx-auto mt-12 justify-center">
     <h3 className="font-heading font-extrabold text-3xl text-center">{t('deleting_account')}</h3>
      <div className="grid grid-cols-1 md:mt-12 mx-6 md:mx-64 mb-12">
        <div className="space-y-5">
          <div>
            <h1 className="text-md font-semibold">{t('deleting_account_text')}</h1>
            <div className="mt-2">
              <ul>
                <li><h2>{envConfig.ContactUsAddress1}</h2></li>
                <li><h2>{envConfig.ContactUsAddress2}</h2></li>
                <li><h2>{envConfig.ContactUsAddress3}</h2></li>
              </ul>
              <a className="text-primary hover:text-accent" href={`mailto:${envConfig.ContactUsEmail}`}>
                {envConfig.ContactUsEmail}
              </a>
            </div>
          </div>
        </div>
      </div>  
    </div>
  );
}

export default Privacy;
