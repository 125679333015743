import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import banner from './assets/banners/dynbanner4.jpg';
import bannerspa from './assets/banners/dynbanner5.png';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import envConfig from '../../../../envConfig';
import { getItemDescription, getItemName } from '../../../../functions';

function DynamicBanner3({
   type, /* Products Type to show (specials, any, regular) */
   itemCount, /* Array size */
   department, /* filter items form specific department */
   responsive,
  }) {
    const { i18n, t } = useTranslation();
// const apiUrl = 'catalog/bestselling/weekly';
const [data, setData] = useState(null);
const lang = localStorage.getItem(`${envConfig.StorageKey}-lang`);
const breakpoint = useBreakpoint();
// const [loading, setLoading] = useState(false);
// const setModal = useSetRecoilState(modalAtom);
// const setProduct = useSetRecoilState(productAtom);
const products = [
    {
        brand: 'RICO',
        name: 'Arroz Grano Mediano',
        description: '3 lb',
        imageUrl: 'https://grocefy-dev.azure-api.net/images//query/photos-md/8624bfbf-248a-4db5-8dde-c0fd452ad7fe?subscription-key=c6e5be5365984c75af234452e18a9b0c',
        activePrice: 0.90,
        hasActivePromo: true,
        regularPrice: 1.25,
        weightType: 0,
    },
    {
        brand: 'COCA COLA',
        name: 'Padrino',
        description: '1.75 lt',
        imageUrl: 'https://grocefy-dev.azure-api.net/images//query/photos-sm/8ff75ae1-6178-46fc-bba0-10f957b36250?subscription-key=c6e5be5365984c75af234452e18a9b0c',
        activePrice: 1.99,
        hasActivePromo: true,
        regularPrice: 2.50,
        weightType: 0,
    },
    {
        brand: 'BERTOLLI',
        name: 'Garlic Alfredo Sauce',
        description: '15 oz',
        imageUrl: 'https://grocefy-dev.azure-api.net/images//query/photos-md/e4f86e52-0794-40fc-8041-b45b8855ef3d?subscription-key=c6e5be5365984c75af234452e18a9b0c',
        activePrice: 5.99,
        hasActivePromo: false,
        regularPrice: 5.99,
        weightType: 0,
    }
]

function randomNumberInRange(max) {
  return Math.floor(Math.random() * max);
}

const fetchTop = function () {
    return setData(products[randomNumberInRange(2)]);
}

// const fetchTop = function () {
//     const payload = {
//       businessId: store?.id,
//       size: itemCount === null ? 5 : itemCount,
//       page: 0,
//       weeksback: 1
//     };
//     setLoading(true);
//     api.post(apiUrl, payload)
//       .then((response) => {
//         const max = response.data.data.results.length;
//         setData(response.data.data.results[randomNumberInRange(max)]);    
//         // console.log('Top Product', response.data.data.results?.[0]);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.log(error);
//         setLoading(false);
//       });
// }

  useEffect(() => {
        fetchTop();
  }, []);

  useEffect(() => {
    fetchTop();
}, [lang]);

  return (
    <div 
      className="bg-white p-4" 
      hidden={breakpoint && breakpoint < 4}
      style={{ position: 'relative', top: 0, roght: 0 }}
    >
         {data?.state === 'loading' && (
        <div className=" flex flex-1 flex-col items-center justify-center h-20">
         ...
        </div>
        )}
    {<div className={data ? 'p-2' : ''}>
      <motion.div
        initial={{
          x: 400,
          opacity: 0,
          transition: {
            duration: 1.0,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        animate={{
          x: 0,
          opacity: 1,
          transition: {
            delay: 2.0,
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        exit={{
          x: 400,
          opacity: 0,
          transition: {
            duration: 1.0,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
      >
        <div className="text-white flex flex-1 flex-col items-center justify-center h-auto"> 
                <div          
                  style={{ position: 'relative', top: 0, roght: 0 }}
                //   role="button"
                //   tabIndex={0}
                //   onKeyDown={() => {
                //     setModal(true);
                //     setProduct(data && data);
                //   }}
                //   onClick={() => {
                //     setModal(true);
                //     setProduct(data && data);
                //   }}
                >
                <img                   
                  alt="background" 
                  src={lang === 'en' ? banner : bannerspa} 
                  className="shadow h-24 sm:h-30 md:h-32"
                />
                <div 
                  style={{ position: 'absolute', bottom: 10, right: 30 }}
                  className="flex flex-1 flex-row items-center"
                >
                  <img                   
                    alt={data?.brand} 
                    src={data?.imageUrl}
                    className="mr-2 p-0 h-10 sm:h-16 md:h-24 lg:h-28 max-h-32"
                  />
                  <div 
                    style={{ lineheight: 10 }}
                    className="flex flex-col items-end text-xs font-medium text-gray-800 leading-4"
                  >
                    <div>{data?.brand}</div>
                    <div>{getItemName((i18n.language, data))}</div>                  
                    <div className="flex flex-col items-end text-xs font-light tracking-tight text-gray-800">
                      <div>{getItemDescription(i18n.language, data)}</div>
                      <div className={data?.hasActivePromo ? '' : 'hidden'}>Reg. ${data?.regularPrice.toFixed(2)}</div>
                    </div>
                  </div>                 
                  <div className={data?.activePrice?.toString().split('.')[0] === '0' ? 'hidden' : 'ml-2 flex flex-row-1'}>
                    <div className="mt-1 font-bold text-lg text-red-600">
                      $
                    </div>
                    <div className="font-bold text-7xl text-red-600">
                      {data?.activePrice?.toString().split('.')[0]}
                    </div>
                    <div className="mt-1 font-bold text-xl text-red-600">
                    {data?.activePrice?.toFixed(2).toString().split('.')[1]}
                    </div>
                    <div className={data?.weightType === 0 ? 'mt-16 ml-1 font-medium text-sm text-red-600' : 'hidden'}>
                      EA
                    </div>
                    <div className={data?.weightType === 1 ? 'mt-16 ml-1 font-medium text-sm text-red-600' : 'hidden'}>
                      LB
                    </div>
                  </div>
                  <div className={data?.activePrice?.toString().split('.')[0] === '0' ? 'ml-2 flex flex-row-1' : 'hidden'}>                    
                    <div className="font-bold text-7xl text-red-600">
                      {data?.activePrice?.toFixed(2).toString().split('.')[1]}
                    </div>
                    <div className="flex flex-col">
                      <div className="mt-0 font-bold text-3xl text-red-600">
                      ¢
                      </div>
                      <div className={data?.weightType === 0 ? 'mt-2 ml-1 font-medium text-sm text-red-600' : 'hidden'}>
                        EA
                      </div>
                      <div className={data?.weightType === 1 ? 'mt-2 ml-1 font-medium text-sm text-red-600' : 'hidden'}>
                        LB
                      </div>
                    </div>
                  </div>
                </div>
                </div>                
        </div>            
      </motion.div>
     </div>}
    </div>
  );
}

export default DynamicBanner3;
