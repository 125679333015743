import dayjs from 'dayjs';
import visa from './assets/img/credit_cards/visa.svg';
import amex from './assets/img/credit_cards/amex.svg';
import diners from './assets/img/credit_cards/diners.svg';
import mastercard from './assets/img/credit_cards/mastercard.svg';
import discover from './assets/img/credit_cards/discover.svg';
import unknown from './assets/img/credit_cards/unknown.svg';

export const toLocalTime = (date) => {
  if (date) {
    return dayjs(date).subtract(4, 'hour');
  }
  return dayjs.utc().subtract(4, 'hour')
};

export const getType = (card) => {
  switch (card) {
    case 'visa':
      return visa;
    case 'amex':
      return amex;
    case 'american express':
      return amex;
    case 'mastercard':
      return mastercard;
    case 'discover':
      return discover;
    case 'diners':
      return diners;
    default:
      return unknown;
  }
};

export const mapPaymentType = (type) => {
  switch (type) {
    case 0:
      return 'Stripe';
    case 1:
      return 'ATH Movil';
    case 7:
      return 'ATH';
    case 3:
      return 'Other';
    case 4:
      return 'EBT';
    case 5:
      return 'Paypal';
    case 10:
      return 'MCS';
    case 11:
      return 'MMM';
    case 16:
      return 'SSS';
    case 17:
      return 'Humana';
    default:
      return type;
  }
};

export const mapOrderType = (type) => {
  switch (type) {
    case 0:
      return 'pickup';
    case 1:
      return 'delivery';
    default:
      return 'undefined';
  }
};

export const mapOrderStatus = (status) => {
  switch (status) {
    case 0:
      return 'status_placed';
    case 2:
      return 'status_delivery_assigned';
    case 3:
      return 'status_accepted';
    case 6:
      return 'status_customer_picked_up';
    case 7:
      return 'status_delivered';
    case 8:
      return 'status_store_cancelled';
    case 9:
      return 'status_user_cancelled';
    case 10:
      return 'status_declined';
    case 5:
      return 'status_delivery_started';
    case 16:
      return 'status_picking_started';
    case 17:
      return 'status_picking_finished';
    case 18:
      return 'status_packing_started';
    case 20:
      return 'status_ready_for_pickup';
    case 21:
      return 'status_pending_delivery_assignment';
    case 27:
      return 'status_pending_paypal_payment';
    case 30:
      return 'status_pending_payment';
    case 32:
      return 'status_under_review';
    case 33:
      return 'status_ivu_control_pending';
    default:
      return 'status_unknown';
  }
};

export function formatNumber(number) {
  if (number) {
    return number.toLocaleString();
  }
  return '';
}

export function getItemName(lang, item) {
  if (lang === 'en') {
    if (!item.name && item.nameSpa) {
      return item.nameSpa;
    }
    return item.name;
  }
  if (item.name && !item.nameSpa) {
    return item.name;
  }
  return item.nameSpa
}

export function getItemDescription(lang, item) {
  if (lang === 'en') {
    if (!item.description && item.descriptionSpa) {
      return item.descriptionSpa;
    }
    return item.description;
  }
  if (item.description && !item.descriptionSpa) {
    return item.description;
  }
  return item.descriptionSpa
}

export const thousand = (n, removeDecimals) => {
  try {
    let number = n;
    if (number === null || number === undefined) {
      return '0';
    }
    if (!number) {
      return '0';
    }
    if (typeof number !== 'number') {
      number = parseFloat(n);
    }
  
    if (number) {
      number = parseFloat(number.toString().replace(',', ''));
      if (number % 1 === 0) {
        number = number.toFixed(0);
      } else {
        number = number.toFixed(2);
      }
      const parts = number.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (!removeDecimals) {
        const result = parts.join('.');
        return result;
      }
      return parts[0];
    }
    return '0';
  } catch (error) {
    return '0';
  }
};

export const toCurrency = (inNumber, removeDecimals) => {
  try {
    let number = inNumber
    if (!number) {
      return '-';
    }
    if (typeof number !== 'number') {
      number = parseFloat(inNumber);
    }
    const wasNegative = number < 0;
    let fixed = 0;
    if (wasNegative) {
      fixed = (-1 * number).toFixed(2);
      // console.log(fixed);
    } else {
      fixed = number.toFixed(2);
    }
    if (wasNegative) {
      return `-$${thousand(fixed, removeDecimals)}`;
    }
    return `$${thousand(fixed, removeDecimals)}`;
  } catch (error) {
    return '-';
  }
};
