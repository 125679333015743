import React from 'react';
import { useTranslation } from 'react-i18next';
import envConfig from '../../../../envConfig';

function Welcome1() {
  const { i18n } = useTranslation();
return (
    <div className="my-2 text-sm md:text-lg w-full">
      <div className={envConfig.TemplateStyle === 'pill' ? 'self-center rounded-xl bg-welcomeBackground overflow-hidden' : 'self-center border border-2xl shadow bg-accentdark overflow-hidden'}>
        <div
          className="text-welcomeText text-justify font-bold mx-2 my-1 tracking-wide text-ellipsis hidden md:block h-auto"
        >{i18n.language === 'en' ? envConfig.WelcomeLongEn : envConfig.WelcomeLongEs}
        </div>
        <div 
          className="text-welcomeText text-justify font-bold mx-2 my-2 tracking-tight vself-center md:hidden h-auto"     
        >{i18n.language === 'en' ? envConfig.WelcomeShortEn : envConfig.WelcomeShortEs}
        </div>
      </div>
    </div>
    )
}
export default Welcome1;

