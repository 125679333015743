import React, { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useClickAway, useSetState } from 'react-use';
import is from 'is_js';
import _ from 'lodash';
import { AnimatePresence, motion } from 'framer-motion';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { businessesSelector } from '../../../../atoms/Selectors';
import { catalogAtom, departmentAtom, departmentDrawerAtom, departmentPageAtom, departmentsAtom, storeAtom } from '../../../../atoms/Atoms';
import { backdropVariants, sidebarVariants, modalVariants } from '../../../../utils';
import api from '../../../../api/api';
import Loader from '../../loader/Loader';
import envConfig from '../../../../envConfig';

function DepartmentsDrawer2() {
const stores = useRecoilValueLoadable(businessesSelector);
const [open, setOpen] = useRecoilState(departmentDrawerAtom);
const ref = useRef();
const setCatalog = useSetRecoilState(catalogAtom);
const departments = useRecoilValue(departmentsAtom);
const setDepartment = useSetRecoilState(departmentAtom);
const setDepartments = useSetRecoilState(departmentsAtom);
const [catalogMapping, setCatalogMapping] = useSetState(null);
const store = useRecoilValue(storeAtom);
const [state, setState] = useRecoilState(departmentPageAtom);
const { i18n, t } = useTranslation();
const history = useHistory();
const style = envConfig.DepartmentsStyle;
const [departmentState, setDepartmentState] = useRecoilState(
  departmentPageAtom
);

  // useClickAway(ref, () => {
  //   setOpen(false);
  // });

useEffect(() => {
  if (store) {
  api.get(`businesses/v2/${store?.id}/web/menu`)
    .then((response) => {
      // setCatalog(response.data.data);
      setDepartments(response.data.data.departments);
    })
    .catch((error) => {
      console.log(error);
    });
  }
}, [store]);

return (
  <>
    <AnimatePresence>
      {open && (
        <motion.div
          variants={backdropVariants}
          initial="hidden h-0"
          animate="visible"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-20"
        >
          <motion.div
            ref={ref}
            variants={sidebarVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={`absolute h-full ${style === 'sidebar-right' ? 'right-0' : 'left-0'}  flex flex-col w-full md:w-64 bg-white`}
          >
        <div className="container mx-auto grid grid-cols-1 gap-4 p-4 overflow-y-auto">
          <div className="flex flex-col-2 items-center">
          {<div className="text-md font-bold">{t('categories_and_departments')}</div>}
          {<button
            type="button"
            onClick={() => setOpen(false)}
            className="hover:opacity-75 outline-none focus:outline-none"
          >
          <FontAwesomeIcon icon={faTimesCircle} className="text-2xl text-actionButton mx-4 md:mx-2" />
           </button>}
          </div>
          {is.null(departments) && <div className="flex content-center items-center"><Loader /></div>}
          {is.not.null(departments) &&
            _.map(departments, (d) => (
              <div
                key={d.id}
                className="flex flex-col text-left rounded-xl bg-white"
              >
                <NavLink
                  className="font-medium transition duration-500 ease-in-out"
                  to={`/stores/${store.id}/departments/${d.id}`}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <div className="line-clamp-1 hover:underline">{i18n.language === 'es' ? d.nameSpa : d.nameEng}</div>
                </NavLink>
                <div className="flex flex-col flex-1 ml-2 py-1">
                  {_.map(_.slice(_.sortBy(d.categories, `${i18n.language === 'es' ? 'nameSpa' : 'nameEng'}`), 0, 99), (c) => (
                    <button
                      type="button"
                      key={c.id}
                      className="w-auto text-left text-xs transition duration-500 easy-in-out outline-none focus:outline-none mb-1 flex leading-none"
                      onClick={() => {
                        setDepartment(d);
                        history.push(`/stores/${store.id}/departments/${d.id}`);
                        setState({
                          ...state,
                          category: c,
                          SearchType: 2,
                          sort: 0,
                          page: 0,
                          hasMore: true,
                          Filters: [],
                          products: [],
                        });
                        setOpen(false);
                      }}
                    >
                      <div className={envConfig.ButtonStyle === 'pill' ? 'truncate text-left capitalize hover:text-white hover:text-scale90 hover:border border-accent rounded rounded-xl hover:bg-accent p-1' : 'underline'}>{i18n.language === 'es' ? c.nameSpa : c.nameEng}</div>
                    </button>
                  ))}
                </div>
              </div>
            ))}
        </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  </>
);
}

export default DepartmentsDrawer2;
