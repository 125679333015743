import React from 'react';
import _ from 'lodash';
import { Menu } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusAlt, faChevronDown, faPhone, faSearch, faShoppingBag, faShoppingBasket } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  locationAtom,
  notificationsAtom,
  searchBrandAtom,
  searchPageAtom,
  searchStringAtom,
  serviceAtom,
  storeAtom,
  serviceSelectionAtom,
  userAtom,
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import { phoneNumber } from '../../../../utils';
import envConfig from '../../../../envConfig';
import ServicesDrawer from '../../service-selection/shared-components/ServicesDrawer';
import CustomDepartmentsControl from '../../custom-depatments-control/CustomDepartmentsControl';

function Subheader1() {
  const [user, setUser] = useRecoilState(userAtom);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const setFilter = useSetRecoilState(searchBrandAtom);
  const store = useRecoilValue(storeAtom);
  const service = useRecoilValue(serviceAtom);
  const [locations, setLocations] = useRecoilState(locationAtom);
  const [searchString, setSearchString] = useRecoilState(searchStringAtom);
  const [search, setSearch] = useRecoilState(searchPageAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [serviceSelection, setServiceSelection] = useRecoilState(serviceSelectionAtom);

  const menuItems = [
    {
      id: '1',
      to: '/',
      text: t('home'),
      hidden: true,
    },
    {
      id: '2',
      to: `/stores/${store && store.id}`,
      text: store && store.name,
      hidden: !store,
    },
    {
      id: '3',
      to: `/stores/${store && store.id}/departments`,
      text: t('departments'),
      hidden: !store,
    },
  ];

  const getProducts = () => {
    if (location.pathname !== `/stores/${store.id}/search`) {
      history.push(`/stores/${store.id}/search`);
    }

    setFilter('');

    setSearch({
      loading: true,
      products: [],
      brands: [],
      page: 0,
    });

    api
      .post(`businesses/V4/${store.id}/${envConfig.SearchItemsPageSize}/0`, {
        SearchType: 0,
        searchString,
      })
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: t('oops'),
              description:
                t('search_error'),
              error: true,
            },
          ]);
          setSearch({
            loading: false,
            products: [],
            brands: [],
            page: 0,
          });
        } else {
          const brands = _.map(
            _.uniqBy(response.data.data.items, 'brand'),
            (item) => item.brand
          );
          setSearch({
            ...search,
            loading: false,
            products: response.data.data.items,
            brands,
            page: 0,
            loadMore: response.data.data.items.length === envConfig.SearchItemsPageSize
          });
        }
      })
      .catch((error) => {
        setSearch({
          loading: false,
          products: [],
          brands: [],
          page: 0,
        });
        setNotifications([
          ...notifications,
          {
            title: t('oops'),
            description:
              t('search_error'),
            error: true,
          },
        ]);
      });
  };

  if (store) {
  if (envConfig.HeaderVersion === '0') {
    return (
      <div className="flex flex-row items-center space-x-2 p-4 bg-white border-b">       
        <nav className="hidden md:flex flex-1 items-center space-x-3">
          {_.map(menuItems, (e) => (
            <NavLink
              key={e.id}
              exact
              activeClassName="text-sky-600"
              to={e.to}
              className="flex items-center text-gray-600 hover:text-gray-500 text-xs font-medium tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0"
            >
              <div className="truncate">{e.text}</div>
            </NavLink>
          ))}
          {location && service === 1 && (
            <Menu>
              <div className="relative">
                <Menu.Button className="w-32 flex items-center text-gray-600 hover:text-gray-500 text-xs font-medium tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">
                  <div className="truncate">
                    {locations.location?.addressLine}
                  </div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </Menu.Button>
                <Menu.Items className="text-xs divide-y absolute z-50 transform -translate-x-1/2 left-1/2 mt-2 h-auto w-60 no-scrollbar overflow-auto bg-white divide-y divide-gray-100 rounded-xl shadow-lg outline-none focus:outline-none">
                  <Menu.Item
                    as="div"
                    className="bg-gray-100 font-semibold text-center p-4 ring-0 outline-none focus:ring-0 focus:outline-none"
                  >
                    {t('delivery_location')}
                  </Menu.Item>
                  <Menu.Item
                    as="div"
                    className="p-4 ring-0 outline-none focus:ring-0 focus:outline-none"
                  >
                    <div className="text-gray-400 mb-2">Localidad Seléccionada</div>
                    <div className="font-semibold">
                      {locations.location?.name}
                    </div>
                    <div>{locations.location?.addressLine}</div>                   
                  </Menu.Item>
                  <Menu.Item
                    as="button"
                    type="buttton"
                    onClick={() => {
                      setLocations({
                        ...locations,
                        modal: true,
                        page: 0,
                        current: 'locations',
                      });
                    }}
                    className="flex items-center justify-center text-center font-semibold p-4 w-full text-left font-medium text-xs tracking-tight bg-gray-800 text-white hover:bg-gray-700 outline-none focus:outline-none ring-0 focus:ring-0 transition duration-500 easy-in-out"
                  >
                    {t('change_address')}
                  </Menu.Item>
                </Menu.Items>
              </div>
            </Menu>
          )}
          {store && (
            <Menu>
              <div className="relative">
                <Menu.Button className="flex items-center space-x-2 text-white hover:text-gray-200 text-xs font-medium tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">
                  <div className="truncate">{t('contact')}</div>
                  <FontAwesomeIcon icon={faPhone} />
                </Menu.Button>
                <Menu.Items className="text-xs divide-y absolute z-50 transform -translate-x-1/2 left-1/2 mt-2 h-auto w-60 no-scrollbar overflow-auto bg-white divide-y divide-gray-100 rounded-xl shadow-lg outline-none focus:outline-none">
                  <Menu.Item
                    as="div"
                    className="p-4 ring-0 outline-none focus:ring-0 focus:outline-none"
                  >
                    <div className="font-semibold">
                      {store.locations[0].name}
                    </div>
                    <div className="text-gray-400">
                      {store.locations[0].address1}.{' '}
                      {store.locations[0].address2}
                    </div>
                    <div className="text-gray-400">
                      {t('phone')}: {phoneNumber(store.locations[0].orderPhone)}
                    </div>
                    <div className="text-gray-400">
                      {t('email')}: {store.locations[0].orderEmail}
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    as="a"
                    href={`tel:${store.locations[0].orderPhone}`}
                    className="flex items-center justify-center text-center font-semibold p-4 w-full text-left font-medium text-xs tracking-tight bg-gray-800 text-white hover:bg-gray-700 outline-none focus:outline-none ring-0 focus:ring-0 transition duration-500 easy-in-out"
                  >
                    {t('call_now')}
                  </Menu.Item>
                </Menu.Items>
              </div>
            </Menu>
          )}
        </nav>
        <div className="flex flex-1 md:flex-none border rounded-md">
          <input
            className="border-0 focus:ring-0 h-10 block w-full rounded-l-md text-gray-400 placeholder-gray-400 font-medium text-xs tracking-tight"
            type="search"
            name="search"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            value={searchString}
            onKeyDown={(event) => {
              if (searchString && event.key === 'Enter') getProducts();
            }}
            onChange={(event) => setSearchString(event.target.value)}
            placeholder={`${t('search_products')}...`}
          />
          <button
            type="button"
            onClick={() => {
              if (searchString) getProducts();
            }}
            className="md:full border-l inline-flex items-center space-x-2 px-4 font-medium tracking-tight rounded-r-md bg-gray-100 hover:bg-gray-50 focus:outline-none transition duration-500 ease-in-out"
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
           ) 
          } if (envConfig.HeaderVersion === '1') {
    return (
  <div className="flex flex-row items-center space-x-2 p-2 bg-accent border-b h-14">
     {(service !== 1 && service !== 0) && (
        <div className="text-white hover:text-gray-100 text-sm font-light ">{`${t('service_prompt')}`}</div>)}
     {service === 0 && (
          <div className="flex flex-row text-sm font-light ">
              <div className="text-white">
                <FontAwesomeIcon icon={faShoppingBag} className="text-lg mr-2" />
              </div>
              <div className="text-white hidden md:block">
                  {t('pickup')} |
              </div>
              <div className="text-white ml-1 hidden md:block">
              {store && store.name}
              </div>
          </div>
        )}
      {/* Delivery */}
      {!location && service === 1 && (
          <div className="flex flex-row  justify-start text-sm font-light m-2">
            <div className="text-white">
              <FontAwesomeIcon icon={faBusAlt} className="text-lg mx-1" />
            </div>
            <div className="text-white mx-1 hidden md:block">
                {t('delivery')} |
            </div>
          </div>
        )}
        {/* Delivery FylOut */}
        {location && service === 1 && (
        <Menu>
          <div className="relative">
            <Menu.Button className=" flex items-start text-white hover:text-gray-200 text-sm  font-light tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">
              <div className="text-white">
                <FontAwesomeIcon icon={faBusAlt} className="text-lg mx-1" />
              </div>
              <div className="text-white mx-1 hidden md:block">
                {t('delivery')} |
              </div>
              <div className="truncate hidden md:block">
                {locations.location?.name}
              </div>
              <FontAwesomeIcon icon={faChevronCircleDown} className="text-lg font-light ml-1" />
            </Menu.Button>
            <Menu.Items className="absolute top-7 text-xs divide-y left-28 md:left-28 absolute z-50 transform -translate-x-1/2 mt-2 h-auto w-60 no-scrollbar overflow-auto bg-white divide-y divide-gray-100 rounded-b-xl shadow-lg outline-none focus:outline-none">
              <Menu.Item
                as="div"
                className="bg-accent font-semibold text-center text-white p-4 ring-0 outline-none focus:ring-0 focus:outline-none"
              >
                {t('delivery_location')}
              </Menu.Item>
              <Menu.Item
                as="div"
                className="p-4 ring-0 outline-none focus:ring-0 focus:outline-none"
              >
                <div className="text-gray-500 mb-2">{t('selected_delivery_location')}</div>
                <div className="font-semibold">
                  {locations.location?.name}
                </div>
                <div>{locations.location?.addressLine}</div>
                <div>{locations.location?.city}</div>
                <div>{locations.location?.zipcode}</div>
              </Menu.Item>
              <Menu.Item
                as="button"
                type="buttton"                
                onClick={() => {
                  setServiceSelection(true);
                }}
                className="bg-base flex items-center justify-center text-center font-semibold p-4 w-full text-left font-medium text-xs tracking-tight bg-gray-800 text-white hover:bg-gray-700 outline-none focus:outline-none ring-0 focus:ring-0 transition duration-500 easy-in-out"
              >
                {t('change_delivery_location')}
              </Menu.Item>
            </Menu.Items>
          </div>
        </Menu>
      )}
      {/* Pickup */}
      {store && service === 0 && 
       (<Menu>
          <div className="relative">
            <Menu.Button className="w-32 flex items-center text-white hover:text-gray-200 text-xs font-light tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">
              <div className="truncate font-bold text-sm hidden">
               {store && store.name}
              </div>
              <FontAwesomeIcon icon={faChevronCircleDown} className="text-lg text-light" />
            </Menu.Button>
            <Menu.Items className="top-7 md:-left-40 -left-10 text-xs divide-y absolute  z-50 transform  mt-2 h-auto w-60 no-scrollbar overflow-auto bg-white divide-y divide-gray-100 rounded-b-xl shadow-lg outline-none focus:outline-none">
              <Menu.Item
                as="div"
                className="bg-accent font-semibold text-white text-center p-4 ring-0 outline-none focus:ring-0 focus:outline-none"
              >
                {t('select_store_prompt')}
              </Menu.Item>
              <Menu.Item
                as="div"
                className="p-4 ring-0 outline-none focus:ring-0 focus:outline-none"
              >
                <div className="text-gray-500 mb-2 hidden">{t(store)}</div>
                <div className="font-semibold">
                  {store.locations[0].name}
                </div>
                <div className="text-gray-600">
                  {store.locations[0].address1}.{' '}
                  {store.locations[0].address2}
                </div>
                <div className="text-gray-600">
                  {t('phone')}: {phoneNumber(store.locations[0].orderPhone)}
                </div>
                <div className="text-gray-600">
                  {t('email')}: {store.locations[0].orderEmail}
                </div>
              </Menu.Item>
              {envConfig.UseSingleStore !== 'true' && (
                <Menu.Item
                  as="button"
                  type="buttton"
                  onClick={() => {
                    setServiceSelection(true);
                  }}
                  className="flex items-center justify-center text-center p-4 w-full text-left font-medium text-xs tracking-tight bg-base text-white hover:bg-base outline-none focus:outline-none ring-0 focus:ring-0 transition duration-500 easy-in-out"
                >
                  {t('change_store_or_service')}
                </Menu.Item>
              )}
            </Menu.Items>
          </div>
        </Menu>
       ) }
    <nav className="hidden md:flex flex-1 items-center space-x-3">
      {_.map(menuItems, (e) => (
        <NavLink
          key={e.id}
          exact     
          activeClassName="text-white underline"
          to={e.to}
          className="flex items-center text-white hover:text-gray-200 text-sm font-light tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0"
        >
          <div className="truncate" hidden={e.hidden}>{e.text}</div>
        </NavLink>
      ))}      
      {store && (
        <Menu>
          <div className="relative">
            <Menu.Button className="flex items-center space-x-2 text-white hover:text-gray-200 text-sm tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">
              <div className="truncate">{t('contact')}</div>
              {/* <FontAwesomeIcon icon={faChevronDown} /> */}
            </Menu.Button>
            <Menu.Items className="text-xs divide-y absolute z-50 transform -translate-x-1/2 left-1/2 mt-2 h-auto w-60 no-scrollbar overflow-auto bg-white divide-y divide-gray-100 rounded-xl shadow-lg outline-none focus:outline-none">
              <Menu.Item
                as="div"
                className="p-4 ring-0 outline-none focus:ring-0 focus:outline-none text-sm"
              >
                <div className="font-semibold">
                  {store.locations[0].name}
                </div>
                <div className="text-gray-500">
                  {store.locations[0].address1}.{' '}
                  {store.locations[0].address2}
                </div>
                <div className="text-gray-500">
                  {t('phone')}: {phoneNumber(store.locations[0].orderPhone)}
                </div>
                <div className="text-gray-500">
                  {t('email')}: {store.locations[0].orderEmail}
                </div>
              </Menu.Item>
              <Menu.Item
                as="a"
                href={`tel:${store.locations[0].orderPhone}`}
                className="flex items-center justify-center text-center font-semibold p-4 w-full text-left font-medium text-xs tracking-tight bg-accent text-white hover:bg-gray-700 outline-none focus:outline-none ring-0 focus:ring-0 transition duration-500 easy-in-out"
              >
                {t('call_now')}
              </Menu.Item>
            </Menu.Items>
          </div>
        </Menu>
      )}
    </nav>
    {store &&
    <CustomDepartmentsControl />}
    <ServicesDrawer />
    <div className="flex flex-1 md:flex-none border rounded-3xl bg-white">
      <input
        className="border-0 focus:ring-0 h-10 block w-full rounded-l-3xl text-gray-400 placeholder-gray-400 font-medium text-xs tracking-tight"
        type="search"
        name="search"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        value={searchString}
        onKeyDown={(event) => {
          if (searchString && event.key === 'Enter') getProducts();
        }}
        onChange={(event) => setSearchString(event.target.value)}
        placeholder={`${t('search_products')}...`}
      />
      <button
        type="button"
        onClick={() => {
          if (searchString) getProducts();
        }}
        className="md:full border-l inline-flex items-center rounded-r-3xl rounded-l-3xl space-x-1 px-4 font-medium tracking-tight rounded-r-md bg-base hover:bg-gray-50 focus:outline-none transition duration-500 ease-in-out"
      >
        <FontAwesomeIcon className="text-white" icon={faSearch} />
      </button>
    </div>
  </div>) 
  }
  }
    return null;
}

export default Subheader1;
