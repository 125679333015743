import React, { useRef, useState } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons';
import { backdropVariants, modalVariants } from '../../../../utils';
import {
  orderAtom,
  serviceAtom,
  storeAtom,
  storesDialogAtom,
  locationAtom
} from '../../../../atoms/Atoms';
import { businessesSelector } from '../../../../atoms/Selectors';
import Loader from '../../../shared-components/loader/Loader';
import envConfig from '../../../../envConfig';
import api from '../../../../api/api';

function StoreDialog() {
  const { i18n, t } = useTranslation();
  const ref = useRef();
  const history = useHistory();
  const stores = useRecoilValueLoadable(businessesSelector);
  const setService = useSetRecoilState(serviceAtom);
  const [state, setState] = useRecoilState(storesDialogAtom);
  const [location, setLocation] = useRecoilState(locationAtom);
  const [store, setStore] = useRecoilState(storeAtom);
  const [order, setOrder] = useRecoilState(orderAtom);
  const [loading, setLoading] = useState(false);

  useClickAway(ref, () => {
    setState(false);
  });

  console.log(stores);

  return (
    <AnimatePresence>
      {state && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="text-gray-800 font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div ref={ref} className="mx-auto max-w-md px-4">
              <div className="rounded-xl shadow-lg relative flex flex-col w-full bg-white">
                <div className="flex items-start space-x-4 bg-gray-100 rounded-t-xl p-4">
                  <div>
                    <div className="text-lg font-semibold tracking-tight">
                      {t('our_stores')}
                    </div>
                    <div className="text-sm text-gray-500 tracking-tight">
                      {t('select_preferred_store')}
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => setState(false)}
                    className="ring-0 focus:ring-0 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
                {(stores.state === 'loading' || loading) && (
                  <div className=" flex flex-1 flex-col items-center justify-center h-20">
                    <Loader />
                  </div>
                )}
                <div className="flex flex-col p-4 space-y-2">
                  {stores?.state === 'hasValue' && _.map(
                    _.filter(stores.contents.data.data.results,
                      (temp) => temp.locations[0].isPickUpAvailable),
                    (s) => (
                      <button
                        type="button"
                        key={s.id}
                        className="text-left text-sm p-4 rounded-xl bg-white border hover:bg-gray-100 transition duration-500 ease-linear ring-0 ring-offset-0 focus:ring-0 outline-none focus:outline-none"
                        onClick={async () => {
                          if (store && store.id !== s.id) {
                            if (order.length > 0) {
                              await setLoading(true);
                              const items = _.map(order, (o) => o.id);
                              const response = await api.post(`businesses/catalog/changeitemsstore/${s.id}`, items);
                              if (!response.data.error) {
                                const newOrderItems = [];
                                for (let i = 0; i < order.length; i++) {
                                  const filtered = _.filter(response.data.data.items,
                                    (it) => it?.parentItemId === order[i]?.parentItemId);
                                  if (filtered.length > 0) {
                                    const found = {
                                      ...filtered[0],
                                      amount: order[i].amount,
                                      isEBT: filtered[0].isEbt
                                    };
                                    newOrderItems.push(found);
                                  }
                                }
                                await setOrder(newOrderItems);
                              } else {
                                await setOrder([]);
                              }
                              await setLoading(false);
                            }
                            await setStore(s);
                            await setService(0);
                            await setLocation({ ...location, location: null });
                            await localStorage.setItem(
                              `${envConfig.StorageKey}-store`,
                              JSON.stringify(s)
                            );
                            await localStorage.removeItem(`${envConfig.StorageKey}-order`);
                            await history.push(`/stores/${s.id}`);
                          } else {
                            await setStore(s);
                            await setService(0);
                            await setLocation({ ...location, location: null });
                            await localStorage.setItem(`${envConfig.StorageKey}-service`, '0');
                            await localStorage.setItem(
                              `${envConfig.StorageKey}-store`,
                              JSON.stringify(s)
                            );
                            history.push(`/stores/${s.id}`);
                          }
                        }}
                      >
                        <div className="font-semibold tracking-tight">
                          {s.name}
                        </div>
                        <div className="text-xs">{s.locations[0].address1}</div>
                      </button>
                    )
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default StoreDialog;
