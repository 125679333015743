import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { useClickAway } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  orderSuccess,
  successMessageAtom,
  referenceOrderAtom
} from '../../../../atoms/Atoms';
import { backdropVariants, modalVariants } from '../../../../utils';
import { MenuToggle } from '../../../shared-components/MenuToggle';
import { mapOrderStatus, mapOrderType, toCurrency, toLocalTime } from '../../../../functions';

require('dayjs/locale/es');

dayjs.locale('es');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function Confirmation() {
  const history = useHistory();
  const ref = useRef();
  const [open, setOpen] = useRecoilState(orderSuccess);
  const order = useRecoilValue(successMessageAtom);
  const referenceOrder = useRecoilValue(referenceOrderAtom);
  const { t, i18n } = useTranslation();

  useClickAway(ref, () => {
    // setOpen(false);
  });

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            ref={ref}
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="overflow-hidden rounded-t-xl md:rounded-xl w-full overflow-hidden md:w-3/5 lg:w-1/3 h-4/5 md:h-auto bg-white"
          >
            <div className="flex space-x-4 items-start justify-between p-4 bg-gray-100 rounded-t-xl">
              <div>
                <div className="font-extrabold tracking-tight text-2xl">
                  {t('congrats')}
                </div>
                <div className="tracking-tight text-gray-800 font-light text-md">
                  {order &&
                    order?.message
                      ?.replace('Su', 'Tu')
                      ?.replace('orden', 'pedido')
                      ?.replace('comunicándole', 'comunicándote')
                      ?.replace('procesada', 'procesado')}
                </div>
              </div>
              <MenuToggle onClick={() => {
                  setOpen(false);
                  if (!referenceOrder) {
                    history.push('/');
                  }
                }}
              />
            </div>
            <div className="text-gray-400 text-sm p-4">
              <div className="text-gray-800 text-base mb-4 font-medium tracking-tight">
                {t('order_details')}
              </div>
              <div>
                {t('store')}:{' '}
                <span className="text-accent font-medium">
                  {order && order.data.businessName}
                </span>
              </div>
              <div>
                {t('order_code')}:{' '}
                <span className="text-accent font-medium">
                  {order && order.data.confirmationCode}
                </span>
              </div>
              <div>
                {t('order_client')}:{' '}
                <span className="text-accent font-medium">
                  {order && order.data.pickUpName}
                </span>
              </div>
              <div>
                {t('order_service')}:{' '}
                <span className="text-accent font-medium">
                  {order && t(mapOrderType(order.data.type))}
                </span>
              </div>
              <div>
                {t('order_service_hour')}:{' '}
                <span className="text-accent font-medium">
                  {order &&
                    toLocalTime(order.data.endSlotTime)
                    .format('dddd, D [de] MMMM, YYYY h:mm A')}
                </span>
              </div>
              <div>
                {t('payment')}:{' '}
                <span className="text-accent font-medium">
                  {order && order.data.paymentName}
                </span>
              </div>
              <div>
                {t('status')}:{' '}
                <span className="text-accent font-medium">
                  {order && t(mapOrderStatus(order.data.status))}
                </span>
              </div>
              <div className=" text-gray-500">
                {t('note')}:{' '}
                <span className="text-gray-600 font-medium">
                  {order && order.data.specialInstruction
                    ? order.data.specialInstruction
                    : 'Ninguna'}
                </span>
              </div>
              <div className="py-6">
                <div className=" ext-gray-800">
                  {t('items')}:{' '}
                  <span className="text-accent font-medium">
                    {order && order.data.itemCount}
                  </span>
                </div>
                <div className=" ext-gray-800">
                  Subtotal:{' '}
                  <span className="text-accent font-medium">
                    {order && toCurrency(order.data.productsTotal)}
                  </span>
                </div>
                <div>
                  {t('municipal_tax')}:{' '}
                  <span className="text-accent font-medium">
                    {order && toCurrency(order.data.municipalTax)}
                  </span>
                </div>
                <div className=" text-gray-400">
                  {t('state_tax')}:{' '}
                  <span className="text-accent font-medium">
                    {order && toCurrency(order.data.stateTax)}
                  </span>
                </div>
                <div className=" text-gray-400">
                  {t('order_service_charge')}:{' '}
                  <span className="text-accent font-medium">
                    {order && toCurrency(order.data.processingFee + order.data.deliveryTotal)}
                  </span>
                </div>
                <div className="text-black-400">
                  Total:{' '}
                  <span className="text-gray-800 font-bold">
                    {order && toCurrency(order.data.orderTotal)}
                  </span>
                </div>
              </div>
              <div className="py-4">
                <Link
                  to={`/order/${order && order.data.confirmationCode}`}
                  className="text-rose-500 hover:text-rose-400 font-medium tracking-tight transition duration-500 ease-linear"
                >
                  {t('view_details')}
                </Link>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
export default Confirmation;
