import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AnimatePresence, motion } from 'framer-motion';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useClickAway } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import {
  faPaperPlane,
  faTimesCircle,
} from '@fortawesome/pro-duotone-svg-icons';
import { backdropVariants, sidebarVariants } from '../../../utils';
import { chatAtom, notificationsAtom, userAtom } from '../../../atoms/Atoms';
import AdminAvatar from './shared-components/AdminAvatar';
import Loader from '../loader/Loader';
import '../../../assets/main.css';
import envConfig from '../../../envConfig';

dayjs.extend(utc);
dayjs.extend(timezone);

function Chat() {
  const { t, i18n } = useTranslation();
  const ref = useRef(null);
  const messagesEndRef = useRef();
  const currentChat = useRef(null);
  const user = useRecoilValue(userAtom);
  const [order, setOrder] = useRecoilState(chatAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [loading, setLoading] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [chatConnection, setChatConnection] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');

  currentChat.current = chatMessages;

  const closeChat = () => {
    setOrder(null);
    currentChat.current = [];
    setChatMessages([]);
    setLoading(false);
    setLoadingMessages(false);
    if (chatConnection) {
      chatConnection.stop();
    }
  };

  useClickAway(ref, () => {
    closeChat();
  });

  const sendChatMessage = async () => {
    if (chatConnection && messageInput && messageInput.trim().length > 0) {
      if (chatConnection.connectionState === HubConnectionState.Connected) {
        await chatConnection.send(
          'SendMessageToOrderChat',
          order.id,
          { Message: messageInput.trim() },
          user.id
        );
        setMessageInput('');
        setLoading(true);
      }
    }
  };

  const chatConnectionError = (errorMessage) => {
    setNotifications([
      ...notifications,
      {
        title: 'Error de conexión',
        description: errorMessage,
        error: true,
      },
    ]);
    setLoading(false);
  };

  const chatSendMessageError = (errorMessage) => {
    setNotifications([
      ...notifications,
      {
        title: 'Error al enviar mensaje',
        description: errorMessage,
        error: true,
      },
    ]);
    setLoading(false);
  };

  const newChatMessageReceived = (newMessageResponse) => {
    const newMessage = newMessageResponse;
    newMessage.sentByUser = newMessage.userId === user.id;
    const updatedChat = [...currentChat.current];
    updatedChat.push(newMessage);
    setChatMessages(updatedChat);
    setLoading(false);
  };

  const chatMessagesReceived = (messagesResponse) => {
    const orderMessages = messagesResponse;
    const updatedChat = [...currentChat.current];
    for (let i = 0; i < messagesResponse.length; i++) {
      orderMessages[i].sentByUser = orderMessages[i].userId === user.id;
      updatedChat.push(orderMessages[i]);
    }
    setChatMessages(updatedChat);
    setLoading(false);
    setLoadingMessages(false);
  };

  const renderDate = (date) => {
    const dateString = `${date}`.replace('Z', '');
    const tz = dayjs.tz.guess();
    return dayjs(`${dateString}+0000`).tz(tz).format('h:mm a');
  };

  useEffect(() => {
    if (order && user) {
      setLoading(true);
      setLoadingMessages(true);
      const connect = new HubConnectionBuilder()
        .withUrl(`${envConfig.HUB_URL}MessagesHub`)
        .withAutomaticReconnect()
        .build();
      setChatConnection(connect);
    }
  }, [order, user]);

  useEffect(() => {
    if (chatConnection) {
      chatConnection
        .start()
        .then(() => {
          chatConnection.send('ConnectToOrderChat', order.id, user.id);
          chatConnection.on('NewMessage', (response) =>
            newChatMessageReceived(response)
          );
          chatConnection.on('ConnectError', (response) =>
            chatConnectionError(response)
          );
          chatConnection.on('SendMessageError', (response) =>
            chatSendMessageError(response)
          );
          chatConnection.on('OrderMessages', (response) =>
            chatMessagesReceived(response)
          );
        })
        .catch((error) => console.log(error));
    }
  }, [chatConnection]);

  useEffect(() => {
    if (messagesEndRef) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messagesEndRef, chatMessages]);

  return (
    <>
      <AnimatePresence>
        {order && (
          <motion.div
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10"
          >
            <motion.div
              ref={ref}
              variants={sidebarVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className="absolute right-0 h-full flex flex-col w-full md:w-1/3 bg-white"
            >
              <div className="p-4 flex items-center justify-between bg-gray-100">
                <div className="text-gray-800 font-extrabold tracking-tight text-lg">
                  <div>Asistencia</div>
                  <div className="text-xs font-normal">
                    Escribenos sobre preguntas o dudas sobre tu pedido{' '}
                    <span className="font-bold">
                      {order && order.confirmationCode}
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    closeChat();
                  }}
                  className="hover:opacity-75 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                </button>
              </div>
              <div className="flex flex-col flex-1 overflow-y-auto p-4 space-y-4">
                {loadingMessages && (
                  <div className="center-loader">
                    <Loader />
                  </div>
                )}
                {_.map(chatMessages, (message) => (
                  <div
                    key={`mes${message.id}`}
                    className="text-xs text-gray-400"
                  >
                    {message.sentByUser && (
                      <div className="flex flex-col items-end justify-end space-y-2">
                        <div>{renderDate(message.createdAt)}</div>
                        <div className="flex space-x-1 items-end">
                          <div className="min-w-0 max-w-xs px-4 py-2 rounded-lg rounded-br-none bg-sky-600 text-white overflow-hidden">
                            {message.message}
                          </div>
                          <img
                            src={user.avatar}
                            alt="My profile"
                            className="w-6 h-6 rounded-full order-2"
                          />
                        </div>
                      </div>
                    )}
                    {!message.sentByUser && (
                      <div className="flex flex-col justify-start space-y-2">
                        <div>{renderDate(message.createdAt)}</div>
                        <div className="flex space-x-1 items-end">
                          <AdminAvatar className="w-6 h-6" />
                          <div className="max-w-xs px-4 py-2 rounded-lg rounded-bl-none bg-gray-300 text-gray-600 overflow-hidden">
                            {message.message}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
              <div>
                <div className="chat-input relative items-center flex p-4 bg-gray-100">
                  <input
                    type="text"
                    disabled={loading}
                    value={messageInput}
                    onChange={(event) => setMessageInput(event.target.value)}
                    placeholder={t('message_placeholder')}
                    className="text-xs border-gray-200 border-gray-200 rounded-full w-full ring-2 ring-offset-2 ring-gray-200 focus:ring-offset-2 focus:ring-2 focus:ring-gray-300 focus:border-gray-200 transition duration-500 ease-in-out"
                  />
                  <div className="absolute right-5 inset-y-0 flex items-center">
                    <button
                      type="button"
                      disabled={loading}
                      onClick={sendChatMessage}
                      className="text-sm flex items-center justify-center rounded-full py-2 px-3 bg-cyan-500 hover:bg-cyan-400 text-white outline-none focus:outline-none transition duration-500 ease-in-out"
                    >
                      {loading && (
                        <Loader />
                      )}
                      {!loading && (
                        <FontAwesomeIcon icon={faPaperPlane} />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default Chat;
