import React, { useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faShoppingBasket, faLanguage } from '@fortawesome/pro-duotone-svg-icons';
import { Link, useHistory, useParams, NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState
} from 'recoil';
import LogRocket from 'logrocket';
import {
  menuAtom,
  orderAtom,
  userAtom,
  paymentAtom,
  sidebarAtom,
  storeAtom,
  tokenAtom,
} from '../../../atoms/Atoms';
import { businessesSelector, checkoutSelector } from '../../../atoms/Selectors';
import envConfig from '../../../envConfig';
import Header1 from './shared-components/Header1';
import Header3 from './shared-components/Header3';
import Header2 from './shared-components/Header2';

function Header(props) {
  const mainLogo = envConfig.MainLogo;
  const languages = [    
    {
      key: 'en',
      lang: 'en',
      name: 'English',
    },
    {
      key: 'es',
      lang: 'es',
      name: 'Español',
    },
  ];
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const refreshStore = useResetRecoilState(businessesSelector);
  const { id } = useParams();
  const [user, setUser] = useRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setPayment = useSetRecoilState(paymentAtom);
  const order = useRecoilValue(orderAtom);
  const setSidebar = useSetRecoilState(sidebarAtom);
  const checkout = useRecoilValue(checkoutSelector);
  const store = useRecoilValue(storeAtom);
  const [menu, setMenu] = useRecoilState(menuAtom);
  const { location } = props;

  useEffect(() => {
    if (store && store.id !== id) {
      if (location && location.pathname !== '/') {
        history.push('/');
      }
    }
  }, [store, order]);

  useEffect(() => {
    if (user && envConfig.UseLogRocket === 'true') {
      LogRocket.identify(user.id, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      });
    }
  }, [user]);

  if (envConfig.HeaderVersion === '1') { 
    return ( 
      <Header1 />
  )
  } if (envConfig.HeaderVersion === '2') {
    return ( 
      <Header2 />
  )
  }
  if (envConfig.HeaderVersion === '3') {
    return ( 
      <Header3 />
  )
  }
  return ( 
    <Header1 />
  )
}

export default Header;
