import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import {
  envAtom,
  notificationsAtom,
  isLoggingInAtom,
} from '../../../../atoms/Atoms';
import Form from '../../../shared-components/form/Form';
import Input from '../../../shared-components/form/Input';
import CleaveInput from '../../../shared-components/form/CleaveInput';
import Select from '../../../shared-components/form/Select';
import Button from '../../../shared-components/buttons/Button';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';

function RequestForm() {
  const { i18n, t } = useTranslation();
  const env = useRecoilValue(envAtom);
  const history = useHistory();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const setIsLoggingIn = useSetRecoilState(isLoggingInAtom);

  const formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      gender: 2,
      language: i18n.language === 'en' ? 0 : 1,
      acceptTos: false,
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      businessName: ''
    },
    validationSchema: Yup.object().shape({
      fname: Yup.string().required(`${t('required_field')}`),
      lname: Yup.string().required(`${t('required_field')}`),
      email: Yup.string()
        .email(`${t('valid_email_validation')}`)
        .required(`${t('required_field')}`),
      phone: Yup.string().required(`${t('required_field')}`),
      city: Yup.string(),
      country: Yup.string(),
      addressLine1: Yup.string(),
      addressLine2: Yup.string(),
      businessName: Yup.string().required(`${t('required_field')}`)
    }),
    onSubmit: (values, { setSubmitting }) => {
      setIsLoggingIn(true);
      const payload = {
        BusinessName: values.businessName,
        FirstName: values.fname,
        LastName: values.lname,
        Email: values.email,
        Gender: parseInt(values.gender, 10),
        Language: parseInt(values.language, 10),
        PhoneNumber: values.phone,
        AddressLine1: values.addressLine1,
        AddressLine2: values.addressLine2,
        City: values.city,
        State: values.state,
        Country: values.country,
      };
      // console.log(payload);
      api.post('oauth/account/request', payload)
        .then((response) => {
          if (!response.data.success) {
            if (response.data.error) {
              setNotifications([
                ...notifications,
                {
                  title: `${t('oops')}`,
                  description: response.data.error,
                  error: true,
                  timeout: 3000
                },
              ]);
            } else {
              setNotifications([
                ...notifications,
                {
                  title: `${t('oops')}`,
                  description:
                  `${t('registration_error')}`,
                  error: true,
                  timeout: 3000
                },
              ]);
            }
          } else {
            setNotifications([
              ...notifications,
              {
                title: `${t('yay')}`,
                description:
                `${t('account_request_success')}`,
                error: false,
                timeout: 3000
              },
            ]);
            formik.resetForm();
            history.push('/');
          }
          setSubmitting(false);
          setIsLoggingIn(false);
        })
        .catch((error) => {
          setNotifications({ ...notifications, signUpError: error.message });
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description: `${t('registration_error')} - ${error.message}`,
              error: true,
            },
          ]);
          setSubmitting(false);
          setIsLoggingIn(false);
        });
    },
  });

  return (
    <>
      <Form className="md:grid-cols-6">
        <Input
          label={t('business_name')}
          className="md:col-span-6"
          required
          type="text"
          name="businessName"
          id="businessName"
          autoCapitalize="true"
          value={formik.values.businessName}
          onChange={formik.handleChange}
          error={
            formik.errors.businessName && formik.touched.businessName && formik.errors.businessName
          }
        />
        <Input
          label={t('name')}
          className="md:col-span-3"
          required
          type="text"
          name="fname"
          id="fname"
          autoCapitalize="true"
          value={formik.values.fname}
          onChange={formik.handleChange}
          error={
            formik.errors.fname && formik.touched.fname && formik.errors.fname
          }
        />
        <Input
          label={t('lastname')}
          className="md:col-span-3"
          required
          type="text"
          name="lname"
          id="lname"
          autoCapitalize="true"
          value={formik.values.lname}
          onChange={formik.handleChange}
          error={
            formik.errors.lname && formik.touched.lname && formik.errors.lname
          }
        />
        <Input
          className="md:col-span-3"
          label={t('contact_email')}
          required
          type="email"
          name="email"
          id="email"
          placeholder={t('sample_email')}
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={
            formik.errors.email && formik.touched.email && formik.errors.email
          }
        />
        <CleaveInput
          className="md:col-span-3"
          label={t('contact_phone')}
          required
          id="phone"
          name="phone"
          type="text"
          autoComplete="tel"
          options={{
            phone: true,
            phoneRegionCode: 'PR',
          }}
          value={formik.values.phone}
          onChange={(e) => {
            formik.setFieldValue('phone', e.target.rawValue);
          }}
          placeholder="787 777 7777"
          error={
            formik.errors.phone && formik.touched.phone && formik.errors.phone
          }
        />
        <Select
          className="md:col-span-3"
          label={t('gender')}
          id="gender"
          name="gender"
          value={formik.values.gender}
          onChange={formik.handleChange}
          options={[
            {
              key: '2',
              value: 2,
              text: `${t('unkown_gender')}`,
            },
            {
              key: '0',
              value: 0,
              text: `${t('male')}`,
            },
            {
              key: '1',
              value: 1,
              text: `${t('female')}`,
            },
          ]}
        />
        <Select
          className="md:col-span-3"
          label={t('language')}
          id="language"
          name="language"
          value={formik.values.language}
          onChange={formik.handleChange}
          options={[
            {
              key: '0',
              value: 0,
              text: `${t('english')}`,
            },
            {
              key: '1',
              value: 1,
              text: `${t('spanish')}`,
            },
          ]}
        />
        <Input
          label={t('address_line_1')}
          className="md:col-span-6"
          required
          type="text"
          name="addressLine1"
          id="addressLine1"
          value={formik.values.addressLine1}
          onChange={formik.handleChange}
        />
        <Input
          label={t('address_line_2')}
          className="md:col-span-6"
          required
          type="text"
          name="addressLine2"
          id="addressLine2"
          value={formik.values.addressLine2}
          onChange={formik.handleChange}
        />
        <Input
          className="md:col-span-2"
          label={t('city')}
          type="text"
          name="city"
          id="city"
          value={formik.values.city}
          onChange={formik.handleChange}
        />
        <Input
          className="md:col-span-2"
          label={t('state')}
          type="text"
          name="state"
          id="state"
          value={formik.values.state}
          onChange={formik.handleChange}
        />
        <Input
          className="md:col-span-2"
          label={t('country')}
          type="text"
          name="country"
          id="country"
          value={formik.values.country}
          onChange={formik.handleChange}
        />
      </Form>
      <div className="flex items-center mt-2">
        <input
          value={formik.values.acceptTos}
          name="acceptTos"
          type="checkbox"
          onChange={formik.handleChange}
          className="w-4 h-4 text-accent bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label htmlFor="marketing-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {t('check_to_accept_tos')}: <a href={envConfig.TosUrl} target="_blank" rel="noreferrer" className="text-accent hover:underline text-sm font-regular tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">{t('view_here')}</a>
        </label>
      </div>
      <Button
        text={t('cancel')}
        textColor="text-gray-400"
        onClick={(e) => {
          e.preventDefault();
          history.goBack();
        }}
        className="bg-gray-200 hover:bg-gray-300 w-20 mr-2 mt-4"
      />
      <Button
        text={t('register')}
        textColor="text-white"
        disabled={formik.isSubmitting || !formik.values.acceptTos || 
            !formik.values.fname?.length > 0 || !formik.values.lname?.length > 0 ||
            !formik.values.businessName?.length > 0 || !formik.values.addressLine1?.length > 0 || 
            !formik.values.addressLine2?.length > 0 || !formik.values.email?.length > 0 ||
            !formik.values.phone?.length > 0}
        loading={formik.isSubmitting}
        onClick={formik.submitForm}
        loaderColor="bg-white"
        className="bg-accent hover:bg-accent w-20"
      />
    </>
  );
}

export default RequestForm;
