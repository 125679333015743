import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { storeAtom } from '../../../atoms/Atoms';

function BrandCard({ infocard }) {
const store = useRecoilValue(storeAtom);
const { i18n, t } = useTranslation();
const history = useHistory();

return (
<button
  type="button"
  onClick={() => {
    history.push(`/stores/${store.id}/brand/0/${infocard.action.replace('action?url=brandsearch:', '')}`);
  }}          
  key={infocard.id} 
  className="flex flex-col border rounded-xl m-5 mx-auto bg-accent"            
  style={{ position: 'static' }}
>
    <img 
      style={{ position: 'relative', bottom: 0, right: 0 }}
      className="h-34 xs:h-32 sm:h-36 md:h-38 lg:h-60 xl:h-72 bg-cover rounded-tl-lg rounded-tr-lg m-0" 
      alt="promo" 
      src={i18n.language === 'en' ? infocard.imageUrl : infocard.imageUrl} 
    />
    <div className="flex flex-row m-2">
    <div className="text-xs md:text-sm text-white h-4 sm:h-6 md:h-6 truncate">
      {i18n.language === 'en' ? infocard.bodyEng : infocard.bodySpa}
    </div>
    </div>
</button>
);
}

export default BrandCard;
