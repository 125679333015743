import axios from 'axios';
import envConfig from '../envConfig';

const api = axios.create({
  baseURL: envConfig.API_URL,
  headers: {
    'Ocp-Apim-Subscription-Key': envConfig.API_KEY,
    Authorization: `Bearer ${window.localStorage.getItem(`${envConfig.StorageKey}-token`)}`,
    'x-api-key': envConfig.UtilityKey
  },
});
api.interceptors.request.use(
  (request) => {
    request.headers['Accept-Language'] = `${window.localStorage.getItem(`${envConfig.StorageKey}-lang`)}-US`.replaceAll('"', '');
    request.headers.Authorization = `Bearer ${window.localStorage.getItem(`${envConfig.StorageKey}-token`)}`.replaceAll('"', '');
    return request;
  }
);
// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       window.location = '/login';
//       window.localStorage.removeItem(`${envConfig.StorageKey}-token`);
//       window.localStorage.removeItem(`${envConfig.StorageKey}-user`);
//       window.localStorage.removeItem(`${envConfig.StorageKey}-card`);
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

export default api;
