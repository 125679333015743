import React from 'react';
import { useLocalStorage } from 'react-use';
import envConfig from '../../../../envConfig';

function Avatar({ className }) {
  const [user] = useLocalStorage(`${envConfig.StorageKey}-user`);

  if (user) {
    return (
      <img
        alt={user.completeName}
        src={user.avatar}
        className={`${className} rounded-full h-8 w-8 bg-gray-200`}
      />
    );
  }

  return null;
}

export default Avatar;
