import React, { useState } from 'react';
import envConfig from '../../../envConfig';
import DepartmentsDrawer1 from './shared-components/DepartmentsDrawer1';
import DepartmentsDrawer2 from './shared-components/DepartmentsDrawer2';
import DepartmentsDrawer3 from './shared-components/DepartmentsDrawer3';

function DepartmentsDrawer() {
    if (envConfig.DepartmentsDrawerStyle === '1') {
        return (
          <DepartmentsDrawer1 />
        )
      } 
      if (envConfig.DepartmentsDrawerStyle === '2') {
        return (
          <DepartmentsDrawer2 />
        )
      } 
      if (envConfig.DepartmentsDrawerStyle === '3') {
        return (
          <DepartmentsDrawer3 />
        )
      } 
      return (
        <DepartmentsDrawer1 />
      )
} 

export default DepartmentsDrawer;
