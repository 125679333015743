import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import ItemsCarousel from 'react-items-carousel';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import { useUnmount, useMount } from 'react-use';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowToRight,
  faArrowToLeft,
} from '@fortawesome/pro-duotone-svg-icons';
import { motion } from 'framer-motion';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import Placeholder from './Placeholder';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';
import { storeAtom } from '../../../../atoms/Atoms';
import Card from '../../card/Card';
import { getItemDescription, getItemName } from '../../../../functions';

function SponsoredCarousel1() {
  const { t, i18n } = useTranslation();
  const source = axios.CancelToken.source();
  const breakpoint = useBreakpoint();
  const store = useRecoilValue(storeAtom);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [hasLoaded, sethasLoaded] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const apiUrl = 'businesses/items/recommended';

  function itemsToShow(bp) {
    console.log(bp);
    if (bp > 2) {
      return bp; 
    }
    if (bp === 2) {
      return 2; 
    }
    return 2;
  } 

  const fetchData = function (tempPage) {
    sethasLoaded(true);
    setLoading(true);
      const payload = {
        page: tempPage,
        size: envConfig.DepartmentCarouselSize,
        businessId: store?.id
      };
      api.post(apiUrl, payload)
        .then((response) => {
          if (response.data.data.results.length < envConfig.DepartmentCarouselSize) {
            setHasMore(false);
            setData(response.data.data.results);            
          } else {
            setData([...response.data.data.results, 'placeholder']);
            setData(response.data.data.results);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
  }

  const load = function (isVisible) {
    if (!hasLoaded && isVisible) {
      fetchData(0);
    }
  };

  useMount(() => {
    sethasLoaded(false);
    setPage(0);
    setData([]);
  });

  useUnmount(() => {
    setData([]);
  });

  const fetchMore = () => {
    fetchData(page);
  };

  useEffect(async () => {
    if (store) {
      source.cancel();
      await sethasLoaded(false);
      await setPage(0);
      await setData([]);
      fetchData(0);
    }
  }, [store]);

  return (
    <div hidden={!store}>
    <VisibilitySensor offset={{ bottom: -600 }} onChange={(isVisible) => { load(isVisible); }}>
      <div className={`${data && data.length === 0 && 'hidden'} my-4`}>
        <div className="container flex flex-col space-x-4 px-4">
          <div
            className={`${
                  !loading
                  ? 'text-seconadary'
                  : 'text-gray-400 animate-pulse'
              } md:text-2xl text-md font-extrabold font-heading tracking-tight truncate`}
          >
              {loading && `${t('loading')}`}
              {!loading && data && data.length > 0 && t('sponsored_products')}
          </div>         
        </div>
        <div className="block">
          <ItemsCarousel
            infiniteLoop
            gutter={10}
            activePosition="right"
            chevronWidth={50}
            disableSwipe={false}
            alwaysShowChevrons
            numberOfCards={itemsToShow(breakpoint)}
            slidesToScroll={itemsToShow(breakpoint)}
            showSlither
            firstAndLastGutter
            activeItemIndex={activeItemIndex}
            requestToChangeActive={setActiveItemIndex}
            onActiveStateChange={(state) => {
              if (state.isLastScroll && hasMore) {
                // fetchMore();
              }
            }}
            rightChevron={
              <button
                type="button"
                className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-secondary"
              >
                <FontAwesomeIcon icon={faArrowToRight} />
              </button>
            }
            leftChevron={
              <button
                type="button"
                className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-secondary"
              >
                <FontAwesomeIcon icon={faArrowToLeft} />
              </button>
            }
            classes={{
              itemsWrapper: 'w-full py-2',
              itemWrapper: 'w-full',
            }}
          >
            {_.map(data, (e) => {
              if (e === 'placeholder') {
                return (
                  <motion.div
                    key={e.id}
                    initial={{
                      x: 100,
                      opacity: 0,
                      transition: {
                        duration: 1,
                        ease: [0.43, 0.13, 0.23, 0.96],
                      },
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        duration: 1,
                        ease: [0.43, 0.13, 0.23, 0.96],
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 0,
                      transition: {
                        duration: 1,
                        ease: [0.43, 0.13, 0.23, 0.96],
                      },
                    }}
                  >
                    <Placeholder key={e.id} showPrice={e.activePrice} />
                  </motion.div>
                );
              }
              return (
                <motion.div
                  key={e.id}
                  initial={{
                    x: 100,
                    opacity: 0,
                    transition: {
                      duration: 1,
                      ease: [0.43, 0.13, 0.23, 0.96],
                    },
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      duration: 1,
                      ease: [0.43, 0.13, 0.23, 0.96],
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 0,
                    transition: {
                      duration: 1,
                      ease: [0.43, 0.13, 0.23, 0.96],
                    },
                  }}
                >
                  <Card
                    key={e.id}
                    product={e}
                    name={getItemName(i18n.language, e)}
                    description={getItemDescription(i18n.language, e)}
                    image={e.imageUrl || null}
                    id={e.id}
                    brand={e.brand}
                    price={e.activePrice}
                  />
                </motion.div>
              );
            })}
          </ItemsCarousel>
        </div>
        <div className="hidden grid grid-cols-2">
          {_.map(_.filter(data, (i) => i !== 'placeholder'), (e) => (
            <motion.div
              key={e.id}
              className="m-1"
              initial={{
                x: 100,
                opacity: 0,
                transition: {
                  duration: 1,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  duration: 1,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              }}
              exit={{
                x: 100,
                opacity: 0,
                transition: {
                  duration: 1,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              }}
            >
              <Card
                key={e.id}
                product={e}
                name={getItemName(i18n.language, e)}
                description={getItemDescription(i18n.language, e)}
                image={e.imageUrl || null}
                id={e.id}
                brand={e.brand}
                price={e.activePrice}
              />
            </motion.div>
          ))}
        </div>
      </div>
    </VisibilitySensor>
    </div>
  );
}

export default SponsoredCarousel1;
