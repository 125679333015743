import React from 'react';
import { useUnmount, useMount } from 'react-use';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { locationAtom, popupAtom, storesDialogAtom } from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';
import SponsoredCarousel1 from './shared-components/SponsoredCarousel1';
import SponsoredCarousel2 from './shared-components/SponsoredCarousel2';
import SponsoredCarousel3 from './shared-components/SponsoredCarousel3';


function SponsoredCarousel() {
  const setDialog = useSetRecoilState(storesDialogAtom);

  if (envConfig.HomeVersion === '1') {
    return <SponsoredCarousel1 />
} if (envConfig.HomeVersion === '2') { 
  return <SponsoredCarousel2 />
} 
if (envConfig.HomeVersion === '3') { 
  return <SponsoredCarousel3 />
}
return <SponsoredCarousel1 />
}
export default SponsoredCarousel;
