import React from 'react';
import { useTranslation } from 'react-i18next';
import { toCurrency } from '../../../../functions';

function SpecialPrice({ price, hasRandomWeight, regularPrice, savings, isUnitPriceMethod }) {
  const { t, i18n } = useTranslation();
  if (hasRandomWeight) {
    return (
      <div className="font-extrabold font-light tracking-tight py-2">
        <div className="">{toCurrency(parseFloat(price))} lb.</div>
      </div>
    );
  }

  return (
      <div className="flex flex-col items-start">   
        <div className="flex flex-row items-center">     
        <div className="font-extrabold text-3xl font-light tracking-tight text-accent mr-2 py-2">
          {toCurrency(parseFloat(price))}
        </div>
        <div 
          style={{ textDecoration: 'line-through' }}
          className="content-bottom text-sm font-extrabold text-gray-500 mt-2"
        >
          {toCurrency(parseFloat(regularPrice))}
        </div>
        </div> 
        {isUnitPriceMethod && (
          <div className="text-md font-normal tracking-tight text-gray-40 mb-2">
            {t('savings_of')} {toCurrency(parseFloat(savings))}
          </div>
        )}
      </div>
    
  );
}

export default SpecialPrice;
