import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { motion } from 'framer-motion';
import { storeAtom } from '../../../../atoms/Atoms';
import Panel from '../../../shared-components/panel/Panel';
import { H3 } from '../../../shared-components/typography/Title';
import Subtitle from '../../../shared-components/typography/Subtitle';
import Button from '../../../shared-components/buttons/Button';

function Notification() {
  const store = useRecoilValue(storeAtom);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0, x: '100%' }}
      animate={{ opacity: 1, x: 0 }}
      exit={{
        opacity: 0,
        x: 0,
        height: 0,
      }}
      transition={{
        type: 'spring',
      }}
    >
      <Panel dark>
        <H3 text={t('notice')} />
        <Subtitle
          text={t('order_verification_prompt')}
        />
        <Button
          onClick={() => history.push(`/stores/${store && store.id}`)}
          text={t('continue_shopping')}
          className="bg-rose-500 hover:bg-rose-400 w-32 mt-2"
        />
      </Panel>
    </motion.div>
  );
}

export default Notification;
