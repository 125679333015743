import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { userAtom } from '../../../atoms/Atoms';
import Avatar from './shared-components/Avatar';
import envConfig from '../../../envConfig';

function User() {
  const user = useRecoilValue(userAtom);
  if (user) {
    return envConfig.TemplateVersion === '1' ? (
      <div className="flex flex-row-reverse md:flex-row text-xs justify-end">
        <div className="mx-2">
          {user.accountRequest && (
            <div className="font-light tracking-tight truncate">{user.accountRequest.businessName}</div>
          )}
          <div className="font-medium tracking-tight truncate">
            {user.completeName}
          </div>
          <div className="font-light tracking-tight truncate">{user.email}</div>
        </div>
        <Avatar className="my-auto" />
      </div>
    ) : 
    (
      <div className="flex flex-row-reverse md:flex-row text-xs justify-end text-headerText">
        <div className="mx-2">
          {user.accountRequest && (
            <div className="font-light tracking-tight truncate">{user.accountRequest.businessName}</div>
          )}
          <div className="font-medium tracking-tight truncate">
            {user.completeName}
          </div>
          <div className="font-light tracking-tight truncate">{user.email}</div>
        </div>
        <Avatar className="my-auto" />
      </div>
    );
  }
  return null;
}

export default User;
