const envConfig = {
  API_KEY: process.env.REACT_APP_API_KEY ?? 'f96f772757af4170b061e3fcfbd837bf',
  API_URL: process.env.REACT_APP_API_URL ?? 'https://socc-grocefy-api-prod.azurewebsites.net/api',
  HUB_URL: process.env.REACT_APP_HUB_URL ?? 'https://socc-grocefy-api-prod.azurewebsites.net/',
  MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN ?? 'pk.eyJ1IjoiZ29wYW56YS1tYXBib3giLCJhIjoiY2tqeGFnZGJ6MDJncTJvazdqYzd6OHczYSJ9.9ymsPgBfRUtD8O_M8yyGEw',
  PAYPAL_CLIENTID: process.env.REACT_APP_PAYPAL_CLIENTID ?? '123456',
  GEO_API_KEY: process.env.REACT_APP_GEO_API_KEY ?? 'AIzaSyB6jl09IPMyXwc55wjIj40Mi9p2ABtZKf0',
  SearchItemsPageSize: 14,
  StorageKey: process.env.REACT_APP_STORAGE_KEY ?? 'SOCC', 
  DefaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'en', 
  TemplateStyle: process.env.REACT_APP_TEMPLATE_STYLE ?? 'pill',
  TemplateVersion: process.env.REACT_APP_TEMPLATE_VERSION ?? '2',
  HeaderVersion: process.env.REACT_APP_HEADER_VERSION ?? '2',
  HomeVersion: process.env.REACT_APP_HOME_VERSION ?? '2',
  SubHeaderVersion: process.env.REACT_APP_SUBHEADER_VERSION ?? '2',
  StoreVersion: process.env.REACT_APP_STORE_VERSION ?? '2',
  WelcomeLong: process.env.REACT_APP_WELCOME_LONG_ES ?? 'Bienvenido a Grocery Exporters Online. ?Empieza tu compra aqu?!',
  WelcomeShort: process.env.REACT_APP_WELCOME_SHORT_ES ?? 'Bienvenido a Grocery Exporters Online. ?Empieza tu compra aqu?!',
  WelcomeLongEn: process.env.REACT_APP_WELCOME_LONG_EN ?? 'Welcome to Grocery Exporters Online. Start your purchase here!',
  WelcomeShortEn: process.env.REACT_APP_WELCOME_SHORT_EN ?? 'Welcome to Grocery Exporters Online. Start you purchase here!',
  WelcomeLongEs: process.env.REACT_APP_WELCOME_LONG_ES ?? 'Bienvenido a Grocery Exporters Online. ?Empieza tu compra aqu?!',
  WelcomeShortEs: process.env.REACT_APP_WELCOME_SHORT_ES ?? 'Bienvenido a Grocery Exporters Online. ?Empieza tu compra aqu?!',
  DepartmentsStyle: process.env.REACT_APP_DEPARTMENTS_STYLE ?? 'sidebar-right',
  DepartmentsDrawerStyle: process.env.REACT_APP_DEPARTMENTS_DRAWER_STYLE ?? '2',
  DepartmentCarouselSize: 6,
  CardBorderStyle: process.env.REACT_APP_CARD_BORDER_STYLE ?? 'pill',
  ButtonStyle: process.env.REACT_APP_BUTTON_STYLE ?? 'pill',
  SocialFooterVersion: process.env.REACT_APP_SOCIAL_FOOTER_VERSION ?? '1',
  InstagramLink: process.env.REACT_APP_SOCIAL_INSTAGRAM ?? 'https://www.instagram.com/oscarcashcarry/',
  FacebookLink: process.env.REACT_APP_SOCIAL_FACEBOOK ?? 'https://www.facebook.com/OscarCashCarry/',
  WelcomeVersion: process.env.REACT_APP_WELCOME_VERSION ?? '2',
  FeedbackFooterVersion: process.env.REACT_APP_FEEDBACK_FOOTER_VERSION ?? '#{REACT_APP_FEEDBACK_FOOTER_VERSION}#',
  GoogleAnalyticsTrackingId: process.env.REACT_APP_GA_TRACKING_ID ?? 'UA-242304391-2',
  ShowShopper: process.env.REACT_APP_SHOW_SHOPPER ?? 'false',
  UseLogRocket: process.env.REACT_APP_UseLogRocket ?? '#{UseLogRocket}#',
  CartItemLimit: process.env.REACT_APP_ITEM_LIMIT ?? '999999',
  ShowShopperButton: process.env.REACT_APP_SHOW_SHOPPER_BUTTON ?? 'false',
  FacebookPixelId: process.env.REACT_APP_FACEBOOK_PIXEL_ID ?? '#{REACT_APP_FACEBOOK_PIXEL_ID}#',
  TosUrl: process.env.REACT_APP_TOS_URL ?? '#{REACT_APP_TOS_URL}#',
  ShowEbtBeforePopup: process.env.REACT_APP_SHOW_EBT_BEFORE_POPUP ?? '#{REACT_APP_SHOW_EBT_BEFORE_POPUP}#',
  ShowEbtAfterPopup: process.env.REACT_APP_SHOW_EBT_AFTER_POPUP ?? 'false',
  ItemCardVersion: process.env.REACT_APP_ITEM_CARD_VERSION ?? '#{REACT_APP_ITEM_CARD_VERSION}#',
  ContactUsSite: process.env.REACT_APP_CONTACT_US_SITE ?? '#{REACT_APP_CONTACT_US_SITE}#',
  ContactUsEmail: process.env.REACT_APP_CONTACT_US_EMAIL ?? 'servicio@oscarcc.com',
  ContactUsAddress1: process.env.REACT_APP_CONTACT_US_ADDRESSLINE_1 ?? '#{REACT_APP_CONTACT_US_ADDRESSLINE_1}#',
  ContactUsAddress2: process.env.REACT_APP_CONTACT_US_ADDRESSLINE_2 ?? '#{REACT_APP_CONTACT_US_ADDRESSLINE_2}#',
  ContactUsAddress3: process.env.REACT_APP_CONTACT_US_ADDRESSLINE_3 ?? '#{REACT_APP_CONTACT_US_ADDRESSLINE_3}#',
  UtilityKey: process.env.REACT_APP_UTILITY_KEY ?? 'utility-b60bbed139054e2289dfa5d29e4c63aab495f236d3af48168378c41dc671d643',
  UseSingleStore: process.env.REACT_APP_USE_SINGLE_STORE ?? 'true',
  DefaultStoreId: process.env.REACT_APP_DEFAULT_STORE_ID ?? 'A4',
  DefaultOrderType: process.env.REACT_APP_DEFAULT_ORDER_TYPE ?? '0',
  GroupedItems: process.env.REACT_APP_GROUPED_ITEMS ?? 'true',
  ShowCounterManualEdit: process.env.REACT_APP_SHOW_COUNTER_MANUAL_EDIT ?? 'true'
};

export default envConfig;
