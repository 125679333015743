import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition, Disclosure, Dialog } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState
} from 'recoil';
import {
  faAppleCrate,
  faCaretRight,
  faCog,
  faCreditCard,
  faHome,
  faKey,
  faLocationArrow,
  faReceipt,
  faSignIn,
  faLanguage,
  faStore,
  faTimesCircle,
  faUserPlus,
} from '@fortawesome/pro-duotone-svg-icons';
import { NavLink, useHistory } from 'react-router-dom';
import {
  departmentDrawerAtom,
  locationAtom,
  menuAtom,
  paymentAtom,
  storeAtom,
  tokenAtom,
  userAtom,
} from '../../../atoms/Atoms';
import logo from '../../../assets/img/logo/main_logo.png';
import User from '../user/User';
import { phoneNumber } from '../../../utils';
import LoyaltySummary from '../LoyaltySummary';
import { businessesSelector } from '../../../atoms/Selectors';
import envConfig from '../../../envConfig';
import useAnalyticsEventTracker from '../../../useAnalyticsEventTracker';

function Navigator() {
  const eventTracker = useAnalyticsEventTracker('User Actions');
  const languages = [    
    {
      key: 'en',
      lang: 'en',
      name: 'English',
    },
    {
      key: 'es',
      lang: 'es',
      name: 'Español',
    },
  ];
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const refreshStore = useResetRecoilState(businessesSelector);
  const store = useRecoilValue(storeAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setPayment = useSetRecoilState(paymentAtom);
  const [sidebar, setSidebar] = useRecoilState(menuAtom);
  const [location, setLocation] = useRecoilState(locationAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [openDepts, setDeptsOpen] = useRecoilState(departmentDrawerAtom);

  return (
    <Transition.Root show={sidebar} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={sidebar}
        onClose={setSidebar}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-800 bg-opacity-75" />
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={React.Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-white overflow-hidden divide-y divide-gray-100">
                  <div className="px-4 h-20 flex items-center justify-between">
                    <Dialog.Title className="text-lg font-medium text-gray-900">
                      <img
                        alt="logo"
                        src={logo}
                        className="w-32 md:w-48 h-auto"
                      />
                    </Dialog.Title>
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-500 outline-none focus:outline-none ring-0 focus:ring-0"
                        onClick={() => setSidebar(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </button>
                    </div>
                  </div>
                  {user && (
                    <div className="px-4 py-4">
                      <User />
                    </div>
                  )}
                  <Menu as="div" className="relative text-3xl">
                    <div className="relative flex ml-4">
                      <Menu.Button className="hover:scale-90 transform group flex items-center leading-none ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out">
                        <FontAwesomeIcon
                          icon={faLanguage}
                          className="text-languageText"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Menu.Items className="absolute z-20 right-0 mt-2 w-32 ring-0 focus:ring-0 outline-none focus:outline-none">
                        <div className="rounded-lg shadow-lg overflow-hidden">
                          <div className="relative bg-white p-4 space-y-4 text-xs">
                            {_.map(languages, (lang) => (
                              <Menu.Item
                                as="button"
                                key={lang.key}
                                type="button"
                                onClick={() => {
                                  eventTracker('Language Selection', lang.name);
                                  i18n.changeLanguage(lang.lang).then(() => {
                                    window.localStorage.setItem(
                                      `${envConfig.StorageKey}-lang`,
                                      JSON.stringify(lang.lang)
                                    );
                                  });
                                }}
                                className={classNames(
                                  i18n.language !== lang.lang && 'text-gray-400',
                                  'group flex items-center hover:text-gray-500 space-x-2 ring-0 focus:ring-0 outline-none focus:outline-none transition ease-in-out duration-500'
                                )}
                              >
                                <FontAwesomeIcon icon={faLanguage} className="w-4 h-4 text-2xl" />
                                <span>{lang.name}</span>
                              </Menu.Item>
                            ))}
                          </div>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu> 
                  <nav className="flex flex-col flex-1 overflow-auto divide-y divide-gray-100">
                    <NavLink
                      exact
                      to="/"
                      activeClassName="bg-gray-100"
                      onClick={() => setSidebar(false)}
                      className="px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                    >
                      <FontAwesomeIcon icon={faHome} />
                      <div>{t('home')}</div>
                    </NavLink>
                    {store && (
                      <NavLink
                        exact
                        to={`/stores/${store && store.id}`}
                        activeClassName="bg-gray-100"
                        onClick={() => setSidebar(false)}
                        className="px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                      >
                        <FontAwesomeIcon icon={faStore} />
                        <div> {store.name}</div>
                      </NavLink>
                    )}
                    {store && (
                      <div>
                      <button
                        type="button"
                        key="deptdraw"
                        className={`${envConfig.DepartmentsStyle === 'sidebar-right' || envConfig.DepartmentsStyle === 'sidebar-left' ? '' : 'hidden'} px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none`}
                        onClick={() => {      
                        setSidebar(false);     
                        setDeptsOpen(!openDepts); 
                      }}
                      >
                      <div className="truncate hover:underline">{t('departments')}</div>
                      </button>
                      <NavLink
                        exact
                        hidden
                        id="deptlink"
                        to={`/stores/${store && store.id}/departments`}
                        activeClassName="bg-gray-100"
                        onClick={() => setSidebar(false)}
                        className={`${envConfig.DepartmentsStyle === 'sidebar-right' || envConfig.DepartmentsStyle === 'sidebar-left' ? 'hidden' : ''} px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none`}
                      >
                        <FontAwesomeIcon className="hidden" icon={faAppleCrate} />
                        <div>{t('departments')}</div>
                      </NavLink>
                      </div>
                    )}
                    {user && (
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none">
                              <FontAwesomeIcon
                                icon={faCaretRight}
                                className={`${
                                  open
                                    ? 'text-gray-700 rotate-90'
                                    : 'text-gray-700'
                                } text-base flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150`}
                              />
                              <div>{t('profile')}</div>
                            </Disclosure.Button>
                            <Transition
                              show={open}
                              enter="transition ease-in-out duration-200"
                              enterFrom="transform -translate-y-1 opacity-0"
                              enterTo="transform translate-y-0 opacity-00"
                              leave="transition ease-in-out duration-200"
                              leaveFrom="transform translate-y-0 opacity-100"
                              leaveTo="transform -translate-y-1 opacity-0"
                            >
                              <Disclosure.Panel
                                static
                                className="border-b border-gray-100 bg-white divide-y divide-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                              >
                                {user && (
                                  <NavLink
                                    exact
                                    to="/profile/loyalty"
                                    activeClassName="bg-gray-100"
                                    onClick={() => setSidebar(false)}
                                    className="pl-10 px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                                  >
                                    <LoyaltySummary />
                                  </NavLink>
                                )}
                                <NavLink
                                  to="/profile/orders"
                                  activeClassName="bg-gray-100"
                                  onClick={() => setSidebar(false)}
                                  className="pl-10 pr-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                                >
                                  <FontAwesomeIcon icon={faReceipt} />
                                  <div>{t('orders')}</div>
                                </NavLink>
                                <NavLink
                                  to="/profile/settings"
                                  activeClassName="bg-gray-100"
                                  onClick={() => setSidebar(false)}
                                  className="pl-10 pr-4 py-3  flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                                >
                                  <FontAwesomeIcon icon={faCog} />
                                  <div>{t('my_account')}</div>
                                </NavLink>
                                <NavLink
                                  to="/profile/password"
                                  activeClassName="bg-gray-100"
                                  onClick={() => setSidebar(false)}
                                  className="pl-10 pr-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                                >
                                  <FontAwesomeIcon icon={faKey} />
                                  <div>{t('change_password')}</div>
                                </NavLink>
                                {/* <NavLink
                                  to="/profile/payment"
                                  activeClassName="bg-gray-100"
                                  onClick={() => setSidebar(false)}
                                  className="pl-10 pr-4 py-3 flex items-center space-x-2 text-xs
                                  tracking-tight transition-colors duration-200 ease-linear
                                  hover:bg-gray-100 ring-0 focus:ring-0 outline-none
                                  focus:outline-none"
                                >
                                  <FontAwesomeIcon icon={faCreditCard} />
                                  <div>{t('order_payment')}</div>
                                </NavLink>
                                <NavLink
                                  to="/profile/locations"
                                  activeClassName="bg-gray-100"
                                  onClick={() => setSidebar(false)}
                                  className="pl-10 pr-4 py-3 flex items-center space-x-2 text-xs
                                  tracking-tight transition-colors duration-200 ease-linear
                                  hover:bg-gray-100 ring-0 focus:ring-0 outline-none
                                  focus:outline-none"
                                >
                                  <FontAwesomeIcon icon={faLocationArrow} />
                                  <div>{t('locations')}</div>
                                </NavLink> */}
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    )}
                    {location?.location && (
                      <Disclosure
                        activeClassName="bg-gray-100"
                        className="truncate px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none">
                              <FontAwesomeIcon
                                icon={faCaretRight}
                                className={`${
                                  open
                                    ? 'text-gray-700 rotate-90'
                                    : 'text-gray-700'
                                } text-base flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150`}
                              />
                              <div>{t('selected_location')}</div>
                            </Disclosure.Button>
                            <Transition
                              show={open}
                              enter="transition ease-in-out duration-200"
                              enterFrom="transform -translate-y-1 opacity-0"
                              enterTo="transform translate-y-0 opacity-00"
                              leave="transition ease-in-out duration-200"
                              leaveFrom="transform translate-y-0 opacity-100"
                              leaveTo="transform -translate-y-1 opacity-0"
                            >
                              <Disclosure.Panel
                                static
                                className="border-b border-gray-100 bg-white divide-y divide-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                              >
                                <div className="pl-10 pr-4 py-3 flex flex-1 items-center space-x-2 text-xs text-gray-400 tracking-tight ring-0 focus:ring-0 outline-none focus:outline-none">
                                  {location.location.addressLine}
                                </div>
                                <NavLink
                                  to="/"
                                  onClick={() => {
                                    setLocation({ ...location, modal: true });
                                    setSidebar(false);
                                  }}
                                  className="pl-10 pr-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                                >
                                  <FontAwesomeIcon icon={faLocationArrow} />
                                  <div>{t('change')}</div>
                                </NavLink>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    )}
                    {!user && (
                      <>
                        <NavLink
                          to="/login"
                          activeClassName="bg-gray-100"
                          onClick={() => setSidebar(false)}
                          className="px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100"
                        >
                          <FontAwesomeIcon icon={faSignIn} />
                          <div>{t('signin')}</div>
                        </NavLink>
                        <NavLink
                          to="/register"
                          activeClassName="bg-gray-100"
                          onClick={() => setSidebar(false)}
                          className="space-x-2 px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100"
                        >
                          <FontAwesomeIcon icon={faUserPlus} />
                          <div>{t('register')}</div>
                        </NavLink>
                      </>
                    )}
                  </nav>
                  {store && (
                    <div className="text-xs p-4 bg-gray-100">
                      <div className="font-semibold">{t('contact')}</div>
                      <div className="text-gray-400">
                        {store.locations[0].name}
                      </div>
                      <div className="text-gray-400">
                        {store.locations[0].address1}.{' '}
                        {store.locations[0].address2}
                      </div>
                      <div className="text-gray-400">
                        {t('phone')}: {phoneNumber(store.locations[0].orderPhone)}
                      </div>
                      <div className="text-gray-400">
                        {t('email')}: {store.locations[0].orderEmail}
                      </div>
                    </div>
                  )}
                  {user && (
                    <div className="flex items-center justify-between px-4 h-16 bg-rose-500 hover:bg-rose-400 transition duration-200 ease-in-out">
                      <button
                        type="button"
                        className="text-xs font-medium text-white h-full w-full ring-0 outline-none focus:ring-0 focus:outline-none"
                        onClick={() => {
                          setUser(null);
                          setToken(null);
                          setPayment({
                            card: null,
                            cards: null,
                            loading: true,
                            error: null,
                            options: null,
                          });
                          refreshStore();
                          window.localStorage.removeItem(`${envConfig.StorageKey}-user`);
                          window.localStorage.removeItem(`${envConfig.StorageKey}-card`);
                          window.localStorage.removeItem(`${envConfig.StorageKey}-token`);
                          setSidebar(false);
                          history.push('/login');
                        }}
                      >
                        {t('signout')}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Navigator;
