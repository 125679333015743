import React, { useState } from 'react';
import Card1 from './shared-components/Card1';
import Card2 from './shared-components/Card2';
import Card3 from './shared-components/Card3';
import envConfig from '../../../envConfig';

function Card({
  id,
  name,
  image,
  price,
  small,
  description,
  brand,
  className,
  product,
}) {
  if (envConfig.ItemCardVersion === '2') {
    return (
      <Card2
        id={id}
        name={name}
        image={image}
        price={price}
        small={small}
        description={description}
        brand={brand}
        className={className}
        product={product}
      />
    );
  }
  if (envConfig.ItemCardVersion === '3') {
    return (
      <Card3
        id={id}
        name={name}
        image={image}
        price={price}
        small={small}
        description={description}
        brand={brand}
        className={className}
        product={product}
      />
    );
  }
  return (
    <Card1
      id={id}
      name={name}
      image={image}
      price={price}
      small={small}
      description={description}
      brand={brand}
      className={className}
      product={product}
    />
  );
}

export default Card;
