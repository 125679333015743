import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import is from 'is_js';
import axios from 'axios';
import { useRecoilState, useSetRecoilState } from 'recoil';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faFilter,
  faRocket,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAmountDown,
  faSortNumericDown,
  faSortNumericUp,
  faTimes,
} from '@fortawesome/pro-duotone-svg-icons';
import { useMount, useUnmount } from 'react-use';
import {
  departmentAtom,
  departmentPageAtom,
  catalogAtom,
  departmentsAtom,
  searchFiltersAtom,
} from '../../../atoms/Atoms';
import Card from '../../shared-components/card/Card';
import Placeholder from './shared-components/Placeholder';
import Breadcrumb from './shared-components/Breadcrumb';
import api from '../../../api/api';
import { getItemDescription, getItemName } from '../../../functions';

function Department() {
  const { i18n, t } = useTranslation();
  const divRef = useRef(null);
  const { id, departmentID } = useParams();
  const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersAtom);
  const [state, setState] = useRecoilState(departmentPageAtom);
  const [department, setDepartment] = useRecoilState(departmentAtom);
  const [departments, setDepartments] = useRecoilState(departmentsAtom);
  const axiosSource = axios.CancelToken.source();
  const setCatalog = useSetRecoilState(catalogAtom);
  
  const sortOptions = [
    { text: `${t('recommended')}`, value: 0, icon: faRocket },
    { text: 'A-Z', value: 1, icon: faSortAlphaDown },
    { text: 'Z-A', value: 2, icon: faSortAlphaUp },
    { text: `${t('lower_price')}`, value: 3, icon: faSortNumericDown },
    { text: `${t('greater_price')}`, value: 4, icon: faSortNumericUp },
    { text: `${t('brand')} (A-Z)`, value: 5, icon: faSortAlphaDown },
    { text: `${t('recent')}`, value: 8, icon: faSortAmountDown },
  ];

  const fetchData = () => {
    if (state?.category && !state?.loadingProducts) {
      setState({
        ...state,
        loading: true,
        loadingProducts: true
      });
      api.post(`businesses/V4/${id}/16/${state.page}`, {
        CategoryId: state.category.id,
        SearchType: state.SearchType,
        sort: state.sort,
        Filters: _.map(state.Filters, (e) => e.id),
      }, { cancelToken: axiosSource.token })
        .then((response) => {
          setState({
            ...state,
            page: state.page + 1,
            products: _.uniqBy(
              [...state.products, ...response.data.data.items],
              'id'
            ),
            hasMore: response.data.data.items.length >= 16,
            loading: false,
            loadingProducts: false
          });
        })
        .catch((error) => console.log(error.message));
    }
  };

  const fetchFilters = () => {
    if (state?.category && !state?.loadingFilters) {
      setState({
        ...state,
        loadingFilters: true
      });
      api.post('businesses/searchFilters', {
        BusinessId: id,
        SearchType: 2,
        CategoryId: state.category.id,
      })
        .then((response) => {
          setSearchFilters(response.data.data.results);
          setState({
            ...state,
            loadingFilters: false
          });
        })
        .then(() => fetchData())
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const orderType = (val) => {
    switch (val) {
      case 1:
        return `${t('A_to_Z')}`;
      case 2:
        return `${t('Z_to_A')}`;
      case 3:
        return `${t('lower_price')}`;
      case 4:
        return `${t('greater_price')}`;
      case 5:
        return `${t('brand_A_to_Z')}`;
      case 8:
        return `${t('recent')}`;
      default:
        return `${t('recommended')}`;
    }
  };
  
  useEffect(() => {
    if (!state.loading) {
      fetchData();
    }
    return () => {
      axiosSource.cancel();
    };
  }, [state.sort]);

  useEffect(() => {
    if (!state.loading) {
      fetchData();
    }
    return () => {
      axiosSource.cancel();
    };
  }, [state.Filters.length]);

  useEffect(() => {
    if (department && !state.category) {
      setState({
        ...state,
        category: department.categories[0],
      });
    }
  }, [department, state.category]);

  useEffect(() => {
    if (state.category) {
      // setState({
      //   ...state,
      //   loading: true
      // });
      fetchFilters();
    }
  }, [state.category]);

  useMount(() => {
    if (!departments) {
      api.get(`businesses/v2/${id}/web/menu`)
        .then((response) => {
          setCatalog(response.data.data);
          setDepartments(response.data.data.departments);
          const filter = _.filter(
            response.data.data.departments,
            (e) => e.id === departmentID
          );
          if (filter) {
            setDepartment(filter[0]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const filter = _.filter(departments, (e) => e.id === departmentID);
      if (filter) {
        setDepartment(filter[0]);
      }
    }
  });

  useUnmount(() => {
    setState({
      category: null,
      SearchType: 2,
      sort: 0,
      page: 0,
      hasMore: true,
      Filters: [],
      products: [],
      error: null,
    });
    setDepartment(null);
    setSearchFilters(null);
  });

  return (
    <div className="h-screen flex flex-col overflow-hidden bg-background dark:bg-background">
      <Breadcrumb />
      <div className="flex container mx-auto space-x-0 md:space-x-4 overflow-hidden p-2 md:p-4">
        <div className="hidden md:flex flex-col w-60 overflow-y-auto rounded-xl">
          <div className="bg-white rounded-md shadow-sm rounded-xl p-4 mb-4">
            <div className="space-y-2 overflow-y-auto no-scrollbar w-full">
              {state.category && (
                <>
                  <div className="font-extrabold tracking-tight">
                    {department && (i18n.language === 'es' ? department.nameSpa : department.nameEng)}
                  </div>
                  {department &&
                    _.map(_.sortBy(department.categories, `${i18n.language === 'es' ? 'nameSpa' : 'nameEng'}`), (e) => (
                      <button
                        type="button"
                        key={e.id}
                        disabled={state.category.id === e.id || state.loading}
                        className={`${
                          state.category.id === e.id && 'text-accent'
                        }
                    w-full truncate line-clamp-1 disabled:cursor-not-allowed flex text-left text-xs font-medium line-clamp-1 hover:text-accent outline-none focus:outline-none transition duration-500 ease-in-out
                  `}
                        onClick={() => {
                          divRef.current.scrollIntoView();
                          setState({
                            category: e,
                            SearchType: 2,
                            sort: 0,
                            page: 0,
                            hasMore: true,
                            Filters: [],
                            products: [],
                          });
                        }}
                      >
                        {i18n.language === 'es' ? e.nameSpa : e.nameEng}
                      </button>
                    ))}
                </>
              )}
              {!department && (
                <>
                  <div className="text-gray-200 font-extrabold tracking-tight mb-2 animate-pulse">
                    {t('departments')}...
                  </div>
                  {_.times(20, (e) => (
                    <div
                      key={e}
                      className="h-2 w-full bg-gray-100 rounded-full animate-pulse"
                    />
                  ))}
                </>
              )}
            </div>
          </div>
          {is.not.null(searchFilters) &&
            _.map(searchFilters, (e, index) => (
              <div
                key={index}
                className="bg-white rounded-xl shadow-sm p-4 mb-4"
              >
                <div className="font-extrabold tracking-tight mb-2">
                  {e.groupName}
                </div>
                {_.map(e.filters, (f) => (
                  <div
                    key={f.id}
                    className="flex items-center justify-between text-xs font-medium space-y-2"
                  >
                    <div className="flex items-center space-x-1">
                      <input
                        type="checkbox"
                        name={f.id}
                        checked={_.includes(state.Filters, f)}
                        className="rounded border-gray-300 text-accent cursor-pointer"
                        onChange={(event) => {
                          divRef.current.scrollIntoView();
                          if (event.target.checked) {
                            setState({
                              ...state,
                              page: 0,
                              hasMore: true,
                              products: [],
                              Filters: [...state.Filters, f],
                            });
                          } else {
                            setState({
                              ...state,
                              page: 0,
                              hasMore: true,
                              products: [],
                              Filters: _.filter(
                                state.Filters,
                                (term) => term.id !== f.id
                              ),
                            });
                          }
                        }}
                      />
                      <div className="capitalize">{i18n.langauge === 'es' ? f.nameSpa : f.name}</div>
                    </div>
                    <div className="text-gray-400">{f.count}</div>
                  </div>
                ))}
              </div>
            ))}
        </div>
        <div className="flex flex-col flex-grow overflow-y-auto no-scrollbar space-y-4">
          <div className="flex flex-col md:flex-row md:items-center justify-between space-y-2">
            <div
              className={`${
                state.products.length
                  ? 'text-gray-900'
                  : 'text-gray-300 animate-pulse'
              } text-lg md:text-xl font-extrabold tracking-tight truncate`}
            >
              {(state.products.length &&
                state.category &&
                (i18n.language === 'es' ? state.category.nameSpa : state.category.nameEng)) ||
                `${t('loading_products')}...`}
            </div>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-2">
              <div className="relative md:hidden z-10">
                <Menu>
                  {({ open }) => (
                    <>
                      <Menu.Button className="w-full flex items-center justify-between rounded-md shadow-sm px-4 py-3 space-x-4 bg-white text-xs font-medium hover:bg-gray-50 outline-none focus:outline-none">
                        <span>{t('categories')}</span>
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={
                            open
                              ? 'transform rotate-180 transition duration-500 easy-in-out'
                              : 'transition duration-500 easy-in-out'
                          }
                        />
                      </Menu.Button>
                      <Transition
                        show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="absolute right-0 w-full mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-xl shadow outline-none max-h-70 overflow-y-auto no-scrollbar"
                        >
                          {department &&
                            _.map(department.categories, (e) => (
                              <Menu.Item key={e.id}>
                                <button
                                  type="button"
                                  disabled={
                                    (state.category && state.category.id === e.id) || state.loading
                                  }
                                  onClick={() => {
                                    divRef.current.scrollIntoView();
                                    setState({
                                      category: e,
                                      SearchType: 2,
                                      sort: 0,
                                      page: 0,
                                      hasMore: true,
                                      Filters: [],
                                      products: [],
                                    });
                                  }}
                                  className={`${
                                    state.category &&
                                    state.category.id === e.id &&
                                    'text-accent'
                                  } disabled:cursor-not-allowed text-xs font-light tracking-tight flex items-center space-x-2 w-full p-4 leading-none text-left hover:text-accent transition duration-500 easy-in-out truncate line-clamp-1`}
                                >
                                  {i18n.language === 'es' ? e.nameSpa : e.nameEng}
                                </button>
                              </Menu.Item>
                            ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
              <div className="relative w-full z-10">
                <Menu>
                  {({ open }) => (
                    <>
                      <Menu.Button className="w-full md:w-40 flex items-center justify-between rounded-md shadow-sm px-4 py-3 space-x-4 bg-white text-xs font-medium hover:bg-gray-50 outline-none focus:outline-none">
                        <span>{orderType(state.sort)}</span>
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={
                            open
                              ? 'transform rotate-180 transition duration-500 easy-in-out'
                              : 'transition duration-500 easy-in-out'
                          }
                        />
                      </Menu.Button>
                      <Transition
                        show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="absolute right-0 w-full mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-xl shadow outline-none max-h-70 overflow-y-auto no-scrollbar"
                        >
                          {_.map(sortOptions, (e) => (
                            <Menu.Item key={e.value}>
                              <button
                                type="button"
                                disabled={state.sort === e.value}
                                onClick={() => {
                                  divRef.current.scrollIntoView();
                                  setState({
                                    ...state,
                                    sort: e.value,
                                    page: 0,
                                    hasMore: true,
                                    products: [],
                                  });
                                }}
                                className={`${
                                  state.sort === e.value && 'text-accent'
                                } disabled:cursor-not-allowed text-xs font-light tracking-tight text-left flex items-center space-x-2 w-full p-4 leading-none hover:font-bold transition duration-500 easy-in-out truncate line-clamp-1`}
                              >
                                <FontAwesomeIcon icon={e.icon} />
                                <span>{e.text}</span>
                              </button>
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
          {is.not.empty(state.Filters) && (
            <div className="flex items-center space-x-2 mb-4">
              <div className="text-xs flex items-center space-x-1">
                <FontAwesomeIcon icon={faFilter} />
                <div>{t('filters')}:</div>
              </div>
              {_.map(state.Filters, (filter) => (
                <div
                  key={t.id}
                  className="flex items-center space-x-2 text-xs px-2 py-1 rounded-full font-semibold text-white bg-rose-500 ring ring-rose-500 ring-offset-2"
                >
                  <div className="capitalize">{_.toLower(filter.name)}</div>
                  <button
                    type="button"
                    className="hover:opacity-75 outline-none focus:outline-none"
                    onClick={() => {
                      divRef.current.scrollIntoView();
                      setState({
                        ...state,
                        page: 0,
                        hasMore: true,
                        products: [],
                        Filters: _.filter(
                          state.Filters,
                          (term) => term.id !== filter.id
                        ),
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              ))}
            </div>
          )}
          <div
            className="overflow-y-auto rounded-md overflow-y-auto no-scrollbar"
          >
            <InfiniteScroll
              pageStart={state.page}
              loadMore={fetchData}
              hasMore={state.hasMore}
              loader={
                <Placeholder
                  key={0}
                  quantity={is.not.empty(state.products) ? 4 : 16}
                  initial={is.empty(state.products)}
                />
              }
              useWindow={false}
            >
              <div
                ref={divRef}
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4"
              >
                {_.map(state.products, (e) => (
                  <Card
                    key={e.id}
                    name={getItemName(i18n.language, e)}
                    description={getItemDescription(i18n.language, e)}
                    image={e.imageUrl || null}
                    id={e.id}
                    brand={e.brand}
                    price={e.activePrice}
                    product={e}
                  />
                ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Department;
